import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./AboutPage.scss";
import Layout from "../../components/Layout";
import Separator from "../../components/Separator/Separator";
import translate from "../../lang/translate";

class AboutPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render() {
    return (
      <div className="AboutPage" id="about">
        <Separator content={translate("separator.about-us")} />
        <Layout background="white" direction="column">
          <div className="AboutPage-Block">
            <div className="AboutPage-Block-title h-md">
              {translate("about.header1")}
            </div>
            <div className="AboutPage-Block-container">
              <img className="AboutPage-Block-image img-1" />
              <div className="AboutPage-Block-desc-container">
                <div className="AboutPage-Block-desc-point p-md">
                  {translate("about.point1.1")}
                </div>
                <div className="AboutPage-Block-desc-point p-md">
                  {translate("about.point1.2")}
                </div>
                <div className="AboutPage-Block-desc-point p-md">
                  {translate("about.point1.3")}
                </div>
                <div className="AboutPage-Block-desc-point p-md">
                  {translate("about.point1.4")}
                </div>
              </div>
            </div>
          </div>
          <div className="AboutPage-Block right">
            <div className="AboutPage-Block-title h-md">
              {translate("about.header2")}
            </div>
            <div className="AboutPage-Block-container">
              <div className="AboutPage-Block-image img-2" />
              <div className="AboutPage-Block-desc-container">
                <div className="AboutPage-Block-desc-point p-md">
                  {translate("about.point2.1")}
                </div>
                <div className="AboutPage-Block-desc-point p-md">
                  {translate("about.point2.2")}
                </div>
                <div className="AboutPage-Block-desc-point p-md">
                  {translate("about.point2.3")}
                </div>
                <div className="AboutPage-Block-desc-point p-md">
                  {translate("about.point2.4")}
                </div>
              </div>
            </div>
          </div>
          <div className="AboutPage-Block">
            <div className="AboutPage-Block-title h-md">
              {translate("about.header3")}
            </div>
            <div className="AboutPage-Block-container">
              <div className="AboutPage-Block-image img-3" />
              <div className="AboutPage-Block-desc-container">
                <div className="AboutPage-Block-desc-point p-md">
                  {translate("about.point3.1")}
                </div>
                <div className="AboutPage-Block-desc-point p-md">
                  {translate("about.point3.2")}
                </div>
                <div className="AboutPage-Block-desc-point p-md">
                  {translate("about.point3.3")}
                </div>
                <div className="AboutPage-Block-desc-point p-md">
                  {translate("about.point3.4")}
                </div>
                <div className="AboutPage-Block-desc-point p-md">
                  {translate("about.point3.5")}
                </div>
                <div className="AboutPage-Block-desc-point p-md">
                  {translate("about.point3.6")}
                </div>
              </div>
            </div>
          </div>
          <div className="AboutPage-Block right">
            <div className="AboutPage-Block-title h-md">
              {translate("about.header4")}
            </div>
            <div className="AboutPage-Block-container">
              <div className="AboutPage-Block-image img-4" />
              <div className="AboutPage-Block-desc-container">
                <div className="AboutPage-Block-desc-point p-md">
                  {translate("about.point4.1")}
                </div>
                <div className="AboutPage-Block-desc-point p-md">
                  {translate("about.point4.2")}
                </div>
                <div className="AboutPage-Block-desc-point p-md">
                  {translate("about.point4.3")}
                </div>
                <div className="AboutPage-Block-desc-point p-md">
                  {translate("about.point4.4")}
                </div>
                <div className="AboutPage-Block-desc-point p-md">
                  {translate("about.point4.5")}
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default AboutPage;

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./Separator.scss";

class Separator extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render() {
    return <div className="SeparatorWrapper h-xl">{this.props.content}</div>;
  }
}

export default Separator;

import React, { Component } from "react";
import logo from "../../assets/images/logo.svg";
import "./Header.scss";
import LanguageButton from "../LanguageButton/LanguageButton";
import { NavLink } from "react-router-dom";
import translate from "../../lang/translate";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

const axios = require("axios");
var md5 = require("md5");
class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonOpen: false,
      qualityOpen: false,
      redirect: "",
      mailToken: "",
      isAdmin: false,
    };
  }

  scrollToElement(id) {
    window.scrollTo({
      top: document.getElementById(id).offsetTop - 50,
      left: 0,
      behavior: "smooth",
    });
  }

  handleLogout() {
    let self = this;
    const form = axios
      .post("/logout")
      .then(function (response) {
        if (!response.data.ok)
          NotificationManager.error(translate("n.logged-out"));

        self.setState({
          redirect: response.data.link ? response.data.link : "",
        });
      })
      .catch(function (error) {
        NotificationManager.error(translate("n.logout-error"));
      });
  }

  closeButton = () => {
    this.setState({ buttonOpen: false });
  };

  render() {
    return (
      <div
        className="header"
        onMouseLeave={() => this.setState({ qualityOpen: false })}
      >
        <div className="header-content container row">
          <div className="header-left__col">
            <NavLink className="link-wrapper" to="/">
              <img src={logo} className="logo" alt="sqar logo" />
            </NavLink>

            <div
              onClick={() =>
                this.setState({ buttonOpen: !this.state.buttonOpen })
              }
              className={
                this.state.buttonOpen ? "menu-button-active" : "menu-button"
              }
            />
          </div>
          <div className="header-second_part">
            <div
              className={
                this.state.buttonOpen
                  ? "header-right__col"
                  : "header-right__col close"
              }
            >
              {this.props.urlName && this.props.urlName == "/" ? (
                <React.Fragment>
                  <div
                    className="h-2xs header-text"
                    onClick={() =>
                      this.setState({ buttonOpen: false }, () =>
                        this.scrollToElement("about")
                      )
                    }
                  >
                    {translate("header.about-us")}
                  </div>
                  <a
                    className="h-2xs header-text"
                    href={"/ows.pdf"}
                    target="_blank"
                  >
                    {translate("header.ows")}
                  </a>

                  <div
                    className="h-2xs header-text-quality"
                    onClick={() =>
                      this.setState({ buttonOpen: false }, () =>
                        this.scrollToElement("quality")
                      )
                    }
                  >
                    {translate("header.quality")}
                  </div>

                  <div
                    className="h-2xs header-text"
                    onClick={() =>
                      this.setState({ buttonOpen: false }, () =>
                        this.scrollToElement("kontakt")
                      )
                    }
                  >
                    {translate("header.contact-us")}
                  </div>

                  <NavLink
                    to="/job"
                    className="h-2xs header-text-work"
                    onClick={() => {
                      this.setState({ buttonOpen: false }, () =>
                        this.props.changeUrl("/job")
                      );
                    }}
                  >
                    {translate("header.job")}
                  </NavLink>

                  <NavLink
                    to="/login"
                    className="h-2xs header-text-db"
                    onClick={() => {
                      this.setState({ buttonOpen: false }, () =>
                        this.props.changeUrl("/login")
                      );
                    }}
                  >
                    RDB
                  </NavLink>
                </React.Fragment>
              ) : (this.props.urlName && this.props.urlName == "/send-email") ||
                (this.props.urlName &&
                  this.props.urlName == "/reset-password") ||
                (this.props.urlName && this.props.urlName == "/job") ? (
                <React.Fragment>
                  <NavLink
                    to="/"
                    className="h-2xs header-text"
                    onClick={() => {
                      this.setState({ buttonOpen: false }, () =>
                        this.props.changeUrl("/")
                      );
                    }}
                  >
                    {translate("header.home")}
                  </NavLink>
                  <NavLink
                    to="/login"
                    className="h-2xs header-text-db"
                    onClick={() => {
                      this.setState({ buttonOpen: false }, () =>
                        this.props.changeUrl("/login")
                      );
                    }}
                  >
                    RDB
                  </NavLink>
                </React.Fragment>
              ) : this.props.urlName == "/user" ||
                this.props.urlName == "/admin-panel" ? (
                <React.Fragment>
                  {this.props.urlName == "/user" ? (
                    this.props.isAdmin ? (
                      <NavLink
                        to={`/admin-panel/${this.props.mailToken}`}
                        className="h-2xs header-text"
                        onClick={() => {
                          this.setState({ buttonOpen: false }, () =>
                            this.props.changeUrl("/admin-panel")
                          );
                        }}
                      >
                        {translate("header.admin-panel")}
                      </NavLink>
                    ) : null
                  ) : (
                    <NavLink
                      to={`/user/${this.props.mailToken}`}
                      className="h-2xs header-text"
                      onClick={() => {
                        this.setState({ buttonOpen: false }, () =>
                          this.props.changeUrl("/user")
                        );
                      }}
                    >
                      {translate("header.user-panel")}
                    </NavLink>
                  )}

                  <NavLink
                    to="/login"
                    className="h-2xs header-text"
                    onClick={() => {
                      this.handleLogout();
                      this.setState({ buttonOpen: false }, () =>
                        this.props.changeUrl("/")
                      );
                    }}
                  >
                    {translate("header.logout")}
                  </NavLink>
                </React.Fragment>
              ) : (
                <NavLink
                  to="/"
                  className="h-2xs header-text"
                  onClick={() => {
                    this.setState({ buttonOpen: false }, () => {
                      this.props.changeUrl("/");
                    });
                  }}
                >
                  {translate("header.home")}
                </NavLink>
              )}

              <div className="lang-button-wrapper">
                <LanguageButton
                  changeLocaleState={this.props.changeLocaleState}
                  closeButton={this.closeButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Header;

import { LOCALES } from "../locales";

export default {
  [LOCALES.GERMAN]: {
    /*HEADER*/
    "header.about-us": "ÜBER UNS",
    "header.ows": "VERKAUFSBEDINGUNGEN",
    "header.contact-us": "KONTAKT",
    "header.quality": "Qualitätskontrolle",
    "header.job": "Arbeitsplätze",
    "header.home": "START SEITE",
    "header.logout": "ABMELDEN",
    "header.user-panel": "FOLDER LISTE",
    "header.admin-panel": "ADMIN PLATTFORM",

    /*FOOTER*/
    "footer.company": "DAS UNTERNEHMEN",
    "footer.goal": "User Ziel",
    "footer.about-us": "Über uns",
    "footer.quality": "QUALITÄTSKONTROLLE",
    "footer.quality-delivery": "Qualitätskontrolle der Lieferanten",
    "footer.quality-end": "Abschließende Qualitätskontrolle",
    "footer.job": "ARBEIT",
    "footer.job-offers": "Arbeitsplätze",
    "footer.for-clients": "FÜR KUNDEN",
    "footer.map":
      "Die Weltkarte wurde von der Website heruntergeladen (Link unten), Änderungen: Änderung der Farben und Markierung der Länder",

    /*BREADCRUMBS*/
    "breadcrumbs.goal": "UNSER ZIEL",
    "breadcrumbs.about-us": "ÜBER UNS",
    "breadcrumbs.quality": "Qualitäts- kontrolle",
    "breadcrumbs.contact-us": "KONTAKT",

    /*SEPARATOR*/
    "separator.goal": "UNSER ZIEL",
    "separator.about-us": "ÜBER UNS",
    "separator.quality": "Qualitätskontrolle",
    "separator.contact-us": "KONTAKT",
    "separator.job": "ARBEITSPLÄTZE",
    "separator.job-apply": "Bewerben Sie sich für eine Stelle",

    /*MAIN*/
    "main.first-slide": `SQA&R ist ein im Jahr 2000 initiiertes Projekt, das auf der Leidenschaft und der langjährigen Erfahrung des Inhabers Przemyslaw Kowalewski basiert. Nach vielen Jahren der Arbeit in der Automobilindustrie haben wir begonnen, mit Tycoons auf  „eigene Rechnung"; zu arbeiten. Seitdem haben wir mit vielen Unternehmen eine Zusammenarbeit aufgebaut. Wir vertreten sie in vielen Ländern. Polen, Russland, Tschechische Republik, Slowakei, Deutschland.`,
    "main.second-slide": `SQA&R ist eine Investition in langjährige Erfahrung in Zusammenarbeit mit OEM-Herstellern aus der Automobilindustrie. Riesige Kenntnisse im Motorenbau. Kenntnis von Bereichen potenzieller Verstöße Innovation und volles Engagement für die Problemlösung.
    Fähigkeit zur erweiterten Analyse und Erkennung von Grundursachen für Verstöße.`,
    "main.third-slide": `SQA&R ist ein mittelständisches Unternehmen, das schnell und dynamisch arbeitet und von einer großen Organisationsstruktur nicht behindert wird.`,

    /*GOAL*/
    "goal.header1":
      "Streben nach Kundenzufriedenheit durch genaue Definition der Kundenerwartungen.",
    "goal.header2":
      "Verbesserung unserer eigenen Prozesse, um die Kompetenzen unserer Mitarbeiter so zu entwickeln, dass sie in der Lage sind, die Erwartungen der Kunden zu erfüllen.",
    "goal.header3": "Unsere Produkte bieten wir in 3 Kategorien an:",

    "goal.title1_1": "Qualitätskontrolle der Lieferanten",
    "goal.title1_2": "Abschließende Qualitätskontrolle",
    "goal.title2_1": "100% -ige Inspektion",
    "goal.title2_2": "Qualitätsservice",
    "goal.title2_3": "OUTSOURCING",

    "goal.desc1_1":
      "Wir unterstützen unsere Kunden dabei, die Qualität der Lieferanten in ihrem Unternehmen zu entwickeln.",
    "goal.desc1_2":
      "Wir sind auf die Qualitätskontrolle von Komponenten und Fertigprodukten spezialisiert.",
    "goal.desc2_1":
      "100%-ige  Kontrolle und/oder Reparatur von Komponenten. Controled Shipping Level 2 (Kontrollierte und überwachte Lieferungen Niveau 2)",
    "goal.desc2_2":
      "Zeitlich begrenzte Entsendung des Arbeitnehmers (an den Kunden oder seinen Lieferanten",
    "goal.desc2_3": "Fachleute, Ingenieure, ansässige Mitarbeiter",
    "goal.button": "Mehr über SQA&R",

    /*ABOUT US*/
    "about.header1": "Wir sind im Einsatz, wenn:",
    "about.point1.1": "der Lieferant fehlerhafte Waren geliefert hat",
    "about.point1.2":
      "der Kunde eine 100%-ige Kontrolle der hergestellten Teile verlangt",
    "about.point1.3":
      "der Prozess instabil ist und zusätzliche Kontrollen verlangt",
    "about.point1.4":
      "man ein neues Produkt einführt und mehr Zeitarbeiter benötigt",

    "about.header2": "Wir leisten unsere Dienste:",
    "about.point2.1": "bei Lieferanten",
    "about.point2.2": "bei Kunden",
    "about.point2.3": "an unserem Sitz",
    "about.point2.4": "an anderen Orten (z.B. Labor, externes Lager)",

    "about.header3": "Warum können Sie sich auf uns verlassen:",
    "about.point3.1": "möglichst kurze Ausführungszeit",
    "about.point3.2":
      "wir erbringen unsere Dienstleistungen rund um die Uhr 7 Tage die Woche",
    "about.point3.3":
      "unsere Inspekteure sind erfahren und in Qualitätsfragen absolut kompromisslos",
    "about.point3.4":
      "unsere Mitarbeiter werden im Rahmen der Arbeitsverträge eingesetzt",
    "about.point3.5":
      "wir unterstützen den Inspektionsprozess durch unser Ingenieurwissen",
    "about.point3.6": "wir arbeiten in Anlehnung an die ISO-Norm 9001: 2000",

    "about.header4": "Vorteile unserer Zusammenarbeit mit SQA&R:",
    "about.point4.1":
      "Motivierte Mitarbeiter (Arbeitsvertrag, Gelegenheits- und Gruppenprämie)",
    "about.point4.2":
      "Reduzierte Kosten der Humanressourcen (Personalauswahl, Bedienung, Schulungen, Arbeitskleidung u.ä.)",
    "about.point4.3":
      "Zusammenarbeit mit ausgewählten Mitarbeitenden, ohne Ihren Headcount permanent zu erhöhen",
    "about.point4.4":
      "Haftpflicht- und  Unfallversicherung aufseiten des Dienstleisters",
    "about.point4.5": "Erfahrenes Personal",

    /*QUALITY-CONTROL*/
    "quality.header1": "LIEFERANTENQUALITÄTSKONTROLLE",
    "quality.desc1":
      "Wir sind uns bewusst, wie wichtig pünktliche Teilelieferungen und zusätzliche Kontrollen sind, um den Produktionsablauf nicht zu stören. Durch unsere Dienstleistungen können unsere Kunden sicher sein, dass die für einen reibungslosen Produktionsablauf erforderlichen Werkzeuge in den Händen von Profis sind. Dies garantiert den Kunden Stabilität, Sicherheit und Pünktlichkeit sowie Zeitersparnis und verbessert die Geschäftbetriebe und Indikatoren",
    "quality.header2": "ENDE-KONTROLLE",
    "quality.desc2":
      "Die Endkontrolle ermöglicht eine schnelle Reaktion auf Produktionsprobleme ohne dass die Produktionslinie angehalten und Mitarbeiter eingebunden werden müssen. Es ermöglicht auch die Überwachung des Produktionsprozesses und Reduzierung der Kosten, die durch mögliche Reklamationen des Endkunden entstehen. In jedem unserer Projekte bieten wir technische Unterstützung und liefern einen ausführlichen Bericht über die durchgeführten Dienstleistungen.",

    /*MAP*/
    "map.header": "Länder, in denen wir derzeit unsere Kunden vertreten",

    /*CONTACT*/
    "contact.check-box-desc":
      "Ich bin mit der Erhebung, Verarbeitung und Nutzung meiner personenbezogenen Daten durch SQAR engineering solutions einverstanden",
    "contact.button": "Nachricht senden",
    "contact.welcome":
      "Wir laden Sie ein, mit uns über eine Zusammenarbeit Kontakt aufzunehmen",
    "contact.hour": "Stunde",
    "contact.hour": "hour",
    "contact.select": "Auswählen",

    /*JOB*/
    "job.attachment":
      "Anhang zum Lebenslauf (Formate doc, docx, pdf akzeptiert): ",
    "job.desc1":
      "SQA&R ist ein schnell wachsender Teil eines internationalen Kapitalbündnisses. In unserem Unternehmen rekrutieren wir Menschen, die mit Leidenschaft und Engagement ihre tägliche Arbeit ausführen. Es ist ihre Arbeit und Anstrengung, die so viele Erfolge für unser Unternehmen bringt.",
    "job.header1": "Jobangebote - wir suchen Menschen mit Visionen",
    "job.desc2": "Wir suchen Menschen, die:",
    "job.point1": "ihre technologischen Fähigkeiten entwickeln möchten",
    "job.point2": "Freude an der Arbeit an vielfältigen Projekten haben",
    "job.point3": "gerne denken und neue Lösungen schaffen",
    "job.point4":
      "in einer Umgebung arbeiten möchten, die die Professionalisierung der Arbeit fördert",
    "job.desc3":
      "Unser Arbeitsumfeld ermöglicht eine individuelle Entwicklung, was zu beruflichen Erfolgen und Karrierewachstum beiträgt. Wenn Sie Teil eines solchen Teams sein möchten, melden Sie Ihr Interesse an einer Zusammenarbeit.",
    "job.header2": "Wir suchen Menschen für Stellen:",

    /*LOGIN*/
    "login.header": "Authentifizierung erforderlich",
    "login.password": "Passwort:",
    "login.password-placeholder": "Kennwort",
    "login.login": "Anmelden",
    "login.forgot-password": "Passwort vergessen?",
    "login.dont-have-account":
      "Sie haben kein Konto? Senden Sie Ihre Anfrage per E-Mail an:",

    /*RDB*/
    "rdb.header1": "Zugang zu Online-Berichten",
    "rdb.desc1":
      "Mit der RDB-Anwendung haben Sie Zugang zu aktuellen Berichten über Ihre Arbeit. Von nun an haben Sie alle Berichte zur Hand, ganz gleich, wo Sie sich befinden. Sie können Berichte im XLSX-Format herunterladen",
    "rdb.header2": "Daten rund um die Uhr verfügbar",
    "rdb.desc2":
      "Sie haben jederzeit vollen Zugriff auf Ihre Berichte. Ihre Berichte werden täglich aktualisiert.",
    "rdb.header3": "Einfach zu bedienende Anwendung",
    "rdb.desc3":
      "Ihre Berichte werden in einer Anwendung gespeichert, die sehr intuitiv und einfach zu bedienen ist und deren Hauptzweck darin besteht, Ihnen die Arbeit zu erleichtern.",

    /*SEND-EMAIL*/
    "send-email.header":
      "E-Mail-Adresse zum Zurücksetzen des Passworts eingeben: ",
    "send-email.send": "Senden",

    /*RESET-PASSWORD*/
    "reset-password.header": "Setze das Passwort zurück für Adresse: ",
    "reset-password.new-password": "Neues Passwort:",
    "reset-password.repeat-password": "Passwort wiederholen:",
    "reset-password.button": "Passwort zurücksetzen",

    /*REGISTER-USER*/
    "register.header": "Konto erstellen für: ",
    "register.password": "Passwort:",
    "register.button": "Anmelden",

    /*USER*/
    "user.header": "VERFÜGBARE BERICHTE",
    "user.click-download":
      "Klicken Sie auf den Bericht, um ihn herunterzuladen",
    "user.click-delete": "Klicken Sie auf den Bericht, um ihn löschen",
    "user.click-download-button": "Herunterladen",
    "user.click-delete-button": "Löschen",
    "user.rep-num": "Berichtsnummer",
    "user.rep-name": "Dateiname",
    "user.rep-admin": "Administrator",
    "user.rep-date": "Datum",
    "user.logout": "Abmelden",
    "user.folder-choose": "Ordner aus Liste auswählen",
    "user.no-reports": "Keine Berichte verfügbar...",

    /*ADMIN*/
    "admin.header": "Verwaltungsbereich",
    "admin.user-send-header":
      "Wählen Sie einen der Benutzer aus, an den Sie den Bericht senden möchten",
    "admin.user-send-user": "Benutzer:",
    "admin.user-send-folder": "Ordner:",
    "admin.user-send-folder-list": "Ordnerliste:",
    "admin.user-send-sub-folder": "Unterordner:",
    "admin.user-send-attachment": "Berichtsanhang (xslx-Format): ",
    "admin.user-send-button": "Bericht senden",

    "admin.invite-header": "Einladung zur Registrierung senden",
    "admin.invite-checkbox": "Dem Benutzer Administratorrechte geben",
    "admin.invite-checkbox-super":
      "Dem Benutzer Superadministratorrechte geben",
    "admin.invite-button": "Nachricht senden",

    "admin.delete-user-header": "Benutzer löschen",
    "admin.delete-user-user": "Benutzer:",
    "admin.delete-user-delete": "Benutzer löschen",
    "admin.delete-user-delete-confirm": "Bestätigen Sie Benutzer löschen",

    "admin.add-job-offer-header": "Jobnangebot hinzufügen",

    "admin.delete-job-offer-header": "Jobangebot löschen",
    "admin.delete-job-offer-offer": "Jobangebot:",
    "admin.delete-job-offer-delete": "Jobangebot löschen",
    "admin.delete-job-offer-delete-confirm":
      "Bestätigen Sie die Löschung der Position",

    "admin.logout": "Abmelden",
    "admin.add-folder-header": "Einen neuen Ordner hinzufügen",
    "admin.folder-delete-header": "Ordner löschen",
    "admin.add-folder-button-label": "Ordner hinzufügen",
    "admin.delete-folder-button-label": "Ordner löschen",
    "admin.delete-folder-button-label-confirm":
      "Bestätigen Sie die Löschung des Ordners",
    "admin.change-folder-button-label": "Benutzers ordner ändern",
    "admin.change-folder-button-confirm": "Ordneränderung bestätigen",
    "admin.change-folder-success": "Ordner des Benutzers erfolgreich ändern",
    "admin.change-folder-error":
      "Beim Versuch, den Benutzerordner zu ändern, ist ein Fehler aufgetreten",
    "admin.add-to-mainFolder": "Neuen Kundenordner erstellen",
    "admin.path-label": "Pfad der geöffneten Ordner ...",
    "admin.download-check": "Herunterladen",
    "admin.delete-check": "Löschen",

    "admin.folderCategory": "Ordners",
    "admin.userCategory": "Benutzers",
    "admin.jobCategory": "Jobangebote",

    /*ERROR_404*/
    "404.error": "404 ERROR",
    "404.page": "Seite nicht verfügbar",
    "404.back": "Zurück zur Startseite",

    /*NOTIFICATION*/
    "n.logged-out": "abgemeldet.",
    "n.logout-error": "Problem beim Abmelden.",
    "n.angemeldet": "Erfolgreich eingeloggt!",
    "n.download-data-error": "Fehler beim Herunterladen von Daten.",
    "n.file-added": "Datei hinzugefügt!",
    "n.add-file-error": "Datei kann nicht hinzugefügt werden.",
    "n.user-deleted": "Der Benutzer wurde aus der Datenbank gelöscht",
    "n.user-delete-error": "Benutzer konnte nicht gelöscht werden.",
    "n.angebot-gelöscht": "Der Beitrag wurde aus der Datenbank gelöscht",
    "n.offer-delete-error": "Der Beitrag konnte nicht gelöscht werden",
    "n.invitation-sent": "Eine Einladung wurde verschickt!",
    "n.invitation-send-error": "Einladung konnte nicht versendet werden!",
    "n.user-is-in-base": "Der Benutzer existiert in der Datenbank",
    "n.sure-to-delete-user":
      "Sind Sie sicher, dass Sie den ausgewählten Benutzer löschen wollen?",
    "n.sicher-zu-löschen-Angebot":
      "Sind Sie sicher, dass Sie den ausgewählten Benutzer löschen wollen?",
    "n.false-login-data": "Falsche Anmeldedaten.",
    "n.login-error": "Problem bei der Anmeldung.",
    "n.short-password": "Das Passwort muss mindestens 8 Zeichen lang sein.",
    "n.passwords-not-equal": "Passwörter sind nicht gleich.",
    "n.registration-success": "Die Registrierung war erfolgreich!",
    "n.registration-error": "Registrierung fehlgeschlagen.",
    "n.password-reset-success": "Passwort erfolgreich zurückgesetzt",
    "n.password-reset-error": "Fehler beim Zurücksetzen des Passworts",
    "n.reset-email-sent":
      "Es wurde eine E-Mail gesendet, um das Passwort zurückzusetzen",
    "n.reset-email-send-error": "Fehler beim Senden einer E-Mail.",
    "n.download-file-error": "Fehler beim Herunterladen einer Datei.",
    "n.no-Berichte": "Keine Berichte.",

    "n.contract-type": "Vertragsart",
    "n.job-name": "Job-Name",
    "n.job-offer-points": "Angebotspunkte",
    "n.job-require-points": "Bedarfspunkte",
    "n.job-place": "Arbeitsort",
    "n.job-time": "Job-Zeit",
    "n.job-description": "Stellenbeschreibung",

    "n.consent": "Sie müssen die Zustimmung akzeptieren",

    "n.name": "Vorname",
    "n.lastname": "Nachname",
    "n.phone": "Telefonnumer",
    "n.contact-time": "bevorzugte Kontaktzeit",
    "n.message": "Nachricht",
    "n.company-name": "Name des Unternehmens oder der Organisation",
    "n.job-position": "Stellenwert",
    "n.cv": "CV Anhang",

    "n.fields": "Felder",
    "n.field": "Feld",
    "n.are-empty": "sind leer",
    "n.is-empty": "ist leer",

    "n.wrong-email": "Ungültige E-Mail-Adresse",

    "n.formula-sent-success": "Das Formular wurde abgeschickt.",
    "n.formula-send-error":
      "Beim Absenden des Formulars ist ein Fehler aufgetreten.",

    "n.formula-sent-success": "Das Formular wurde abgeschickt.",
    "n.formula-send-error":
      "Beim Absenden des Formulars ist ein Fehler aufgetreten.",
    "n.offer-sent-success": "Der Beitrag wurde erfolgreich hinzugefügt.",
    "n.offer-send-error":
      "Beim Hinzufügen der Position ist ein Fehler aufgetreten",

    "n.folder-add-success": "Ordner erfolgreich hinzugefügt",
    "n.folder-add-error": "Es gab ein Problem beim Hinzufügen des Ordners",
    "n.folder-delete-success": "Gelöschter Ordner erfolgreich",
    "n.folder-delete-confirm": "Löschung des Ordners bestätigen",
    "n.folder-delete-error": "Es gab ein Problem beim Löschen des Ordners",
    "n.folder-exists": "Der Ordner existiert in der Datenbank",
    "n.file-delete-success": "Gelöschte Datei erfolgreich",
    "n.file-delete-error": "Es gab ein Problem beim Löschen der Datei",
  },
};

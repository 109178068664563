import React, { PureComponent } from "react";
import { I18nProvider, LOCALES } from "../../lang/index";
import "./LanguageButton.scss";

class LanguageButton extends PureComponent {
  constructor(props) {
    super(props);

    this.timer = null;
    this.state = {
      currentFlag:
        navigator.language == "pl" || navigator.userLanguage == "pl"
          ? "PolishFlag"
          : navigator.language == "de" || navigator.userLanguage == "de"
          ? "GermanFLag"
          : "BritishFlag",
      currentFlagNoEffects:
        navigator.language == "pl" || navigator.userLanguage == "pl"
          ? "PolishFlag"
          : navigator.language == "de" || navigator.userLanguage == "de"
          ? "GermanFLag"
          : "BritishFlag",
      displayMenu: false,
      selectedLanguage:
        navigator.language == "pl" || navigator.userLanguage == "pl"
          ? "PL"
          : navigator.language == "de" || navigator.userLanguage == "de"
          ? "DE"
          : "EN",
      showArrow: false,
      GermanFlag: "GermanFlag",
      GermanFlagNoEffects: "GermanFlag",
      PolishFlag: "PolishFlag",
      PolishFlagNoEffects: "PolishFlag",
      BritishFlag: "BritishFlag",
      BritishFlagNoEffects: "BritishFlag",
    };
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  }

  showDropdownMenu(event) {
    event.preventDefault();
    setTimeout(() => {
      this.setState({ displayMenu: true }, () => {
        document.addEventListener("click", this.hideDropdownMenu);
      });
    }, 500);
    this.setState({ showArrow: true });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false, showArrow: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }

  handleOnMouseOver = () => {
    this.setState({ currentFlag: this.state.currentFlagNoEffects + "Hover" });
  };

  handleOnMouseLeave = () => {
    this.setState({ currentFlag: this.state.currentFlagNoEffects });
  };

  handleOnMouseDown = () => {
    this.setState({ currentFlag: this.state.currentFlagNoEffects + "Active" });
  };

  menuLeave = (e) => {
    e.preventDefault();
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({
        displayMenu: false,
      });
    }, 500);
  };

  menuOver = (e) => {
    clearTimeout(this.timer);
  };

  handleOnMouseOverOption = (name) => {
    name == "Polish"
      ? this.setState({ PolishFlag: this.state.PolishFlagNoEffects + "Hover" })
      : name == "British"
      ? this.setState({
          BritishFlag: this.state.BritishFlagNoEffects + "Hover",
        })
      : this.setState({ GermanFlag: this.state.GermanFlagNoEffects + "Hover" });
  };

  handleOnMouseLeaveOption = (name) => {
    name == "Polish"
      ? this.setState({ PolishFlag: this.state.PolishFlagNoEffects })
      : name == "British"
      ? this.setState({
          BritishFlag: this.state.BritishFlagNoEffects,
        })
      : this.setState({ GermanFlag: this.state.GermanFlagNoEffects });
  };

  handleOnMouseDownOption = (name) => {
    name == "Polish"
      ? this.setState({ PolishFlag: this.state.PolishFlagNoEffects + "Active" })
      : name == "British"
      ? this.setState({
          BritishFlag: this.state.BritishFlagNoEffects + "Active",
        })
      : this.setState({
          GermanFlag: this.state.GermanFlagNoEffects + "Active",
        });
  };

  render() {
    return (
      <div className="LanguageButtonWrapper">
        <div
          className="ActiveButton"
          // onMouseOver={this.handleOnMouseOver}
          // onMouseLeave={this.handleOnMouseLeave}
          // onMouseDown={this.handleOnMouseDown}
          // onMouseUp={this.handleOnMouseLeave}
          onClick={() =>
            this.setState({ displayMenu: !this.state.displayMenu })
          }
        >
          <img
            className="flag-img"
            src={`/gallery/${this.state.currentFlag}.svg`}
          />
          {!this.state.displayMenu ? (
            <i className="las la-angle-down" />
          ) : (
            <i className="las la-angle-up" />
          )}
        </div>
        {this.state.displayMenu ? (
          <div
            className="showMenu"
            onMouseLeave={(e) => this.menuLeave(e)}
            onMouseOver={(e) => this.menuOver(e)}
          >
            <div
              className="LangMenuOption"
              // onMouseOver={() => this.handleOnMouseOverOption("Polish")}
              // onMouseLeave={() => this.handleOnMouseLeaveOption("Polish")}
              // onMouseDown={() => this.handleOnMouseDownOption("Polish")}
              // onMouseUp={() => this.handleOnMouseLeaveOption("Polish")}
              onClick={() => {
                this.props.changeLocaleState([LOCALES.POLISH]);
                this.setState(
                  {
                    selectedLanguage: "PL",
                    currentFlagNoEffects: "PolishFlag",
                    currentFlag: "PolishFlag",
                    displayMenu: false,
                  },
                  () => this.props.closeButton()
                );
              }}
            >
              <img
                className="LangMenuImg"
                src={`/gallery/${this.state.PolishFlag}.svg`}
              />
              <p className="LangMenuText">PL</p>
            </div>
            <div
              className="LangMenuOption"
              // onMouseOver={() => this.handleOnMouseOverOption("British")}
              // onMouseLeave={() => this.handleOnMouseLeaveOption("British")}
              // onMouseDown={() => this.handleOnMouseDownOption("British")}
              // onMouseUp={() => this.handleOnMouseLeaveOption("British")}
              onClick={() => {
                this.props.changeLocaleState([LOCALES.ENGLISH]);
                this.setState(
                  {
                    selectedLanguage: "EN",
                    currentFlagNoEffects: "BritishFlag",
                    currentFlag: "BritishFlag",
                    displayMenu: false,
                  },
                  () => this.props.closeButton()
                );
              }}
            >
              <img
                className="LangMenuImg"
                src={`/gallery/${this.state.BritishFlag}.svg`}
              />
              <p className="LangMenuText">EN</p>
            </div>
            <div
              className="LangMenuOption last"
              // onMouseOver={() => this.handleOnMouseOverOption("German")}
              // onMouseLeave={() => this.handleOnMouseLeaveOption("German")}
              // onMouseDown={() => this.handleOnMouseDownOption("German")}
              // onMouseUp={() => this.handleOnMouseLeaveOption("German")}
              onClick={() => {
                this.props.changeLocaleState([LOCALES.GERMAN]);
                this.setState(
                  {
                    selectedLanguage: "DE",
                    currentFlagNoEffects: "GermanFlag",
                    currentFlag: "GermanFlag",
                    displayMenu: false,
                  },
                  () => this.props.closeButton()
                );
              }}
            >
              <img
                className="LangMenuImg"
                src={`/gallery/${this.state.GermanFlag}.svg`}
              />
              <p className="LangMenuText">DE</p>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default LanguageButton;

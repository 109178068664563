import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./SendEmail.scss";
import Button from "../../components/Button/Button";
import Layout from "../../components/Layout";
import logo from "../../assets/images/logo.svg";
import { NavLink, Navigate } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import translate from "../../lang/translate";
const axios = require("axios");
var md5 = require("md5");

class SendEmail extends PureComponent {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      hasError: false,
      redirect: "",
      messageValues: {
        email: "",
      },
    };
  }

  componentDidMount = () => {
    this.props.changeUrl("/send-email");
  };

  handleSubmit = () => {
    const data = {
      email: this.state.messageValues.email,
      resetPassword: 1,
      sendEmail: 1,
    };
    clearTimeout(this.timer);
    let self = this;
    const form = axios
      .post("/api-form-reset-pass", {
        data,
      })
      .then(function (response) {
        if (response.data.ok) {
          NotificationManager.success(translate("n.reset-email-sent"));

          self.setState({ redirect: "/login" });
        } else {
          NotificationManager.error(translate("n.reset-email-send-error"));

          self.setState({ redirect: "/login" });
        }
      })
      .catch(function (error) {
        NotificationManager.error(translate("n.reset-email-send-error"));

        self.setState({ redirect: "/login" });
      });
    const firstNameInput = document.getElementById("email");

    firstNameInput.value = "";
  };

  handleChange = (event, name) => {
    this.setState({
      messageValues: {
        ...this.state.messageValues,
        [name]: event.target.value,
      },
    });
  };

  render() {
    if (this.state.redirect != "") {
      return <Navigate replave to={this.state.redirect} />;
    } else
      return (
        <div className="LoginWrapper">
          <Layout background="white">
            <div className="login-background-img">
              <img src={logo} className="login-logo" alt="sqar logo" />
              <div className="login-title p-md">
                {translate("send-email.header")}
              </div>
              <div className="login-small-title p-md"></div>
              <div className="login-list-input">
                <p className="p-xs login-list-input__header">E-mail</p>
                <input
                  className="login-list-input__input last"
                  placeholder={
                    this.props.localeState == "pl-PL"
                      ? "Podaj adres e-mail"
                      : this.props.localeState == "de-DE"
                      ? "Bitte geben Sie Ihre E-Mail Adresse ein"
                      : "Please enter your e-mail address"
                  }
                  onChange={(e) => this.handleChange(e, "email")}
                  id="email"
                  secureTextEntry={false}
                />
              </div>
              <div className="bottom-part">
                <div
                  className="button-wrapper"
                  onClick={(e) => this.handleSubmit(e)}
                >
                  <Button
                    buttonStyle="filled"
                    title={translate("send-email.send")}
                    className="login-button"
                  />
                </div>
              </div>
            </div>
            <div className="login-background-img-right">
              <div className="login-title-right p-xl  ">REPORT DATA BASE</div>
              <div className="login-container-right">
                <div className="login-desc-img-right">
                  <i className="las la-scroll p-lg" />
                  <div className="login-text-container">
                    <div className="login-desc-title-right p-md ">
                      {translate("rdb.header1")}
                    </div>
                    <div className="login-desc-right  p-2xs">
                      {translate("rdb.desc1")}
                    </div>
                  </div>
                </div>

                <div className="login-desc-img-right">
                  <i className="las la-calendar-day p-md"></i>
                  <div className="login-text-container">
                    {" "}
                    <div className="login-desc-title-right p-md ">
                      {translate("rdb.header2")}
                    </div>
                    <div className="login-desc-right p-2xs">
                      {translate("rdb.desc2")}
                    </div>
                  </div>
                </div>

                <div className="login-desc-img-right">
                  <i className="las la-laptop-code p-md"></i>
                  <div className="login-text-container">
                    <div className="login-desc-title-right p-md ">
                      {translate("rdb.header3")}
                    </div>
                    <div className="login-desc-right p-2xs">
                      {translate("rdb.desc3")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Layout>
        </div>
      );
  }
}

export default SendEmail;

import React, { Component } from "react";
import PropTypes from "prop-types";

import Header from "../../components/Header/Header";
import Form from "../Form/Form";
import Kontakt from "../Kontakt/Kontakt";
import Main from "../Main/Main";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import Quality from "../Quality/Quality";
import Goal from "../Goal/Goal";
import CustomMap from "../CustomMap/CustomMap";
import "../../assets/scss/breakpoints.scss";
import AboutPage from "../AboutPage/AboutPage";

class OnePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }
  componentDidMount = () => {
    this.props.changeUrl("/");
  };

  render() {
    const linkData_PL = [
      {
        header: "Imię:",
        placeholder: `Twoje imię`,
        idName: "firstName",
      },
      {
        header: "Nazwisko:",
        placeholder: `Twoje nazwisko`,
        idName: "lastName",
      },
      {
        header: "E-mail:",
        placeholder: `Twój e-mail`,
        idName: "email",
      },
      {
        header: "Telefon:",
        placeholder: `Wpisz numer telefonu`,
        idName: "phone",
      },
      {
        header: "Preferowany czas kontaktu:",
        placeholder: `Wybierz`,
        dropdown: 1,
        idName: "calendar",
      },
      {
        header: "Nazwa firmy lub organizacji:",
        placeholder: `Uzupełnij`,
        idName: "companyname",
      },
      {
        header: "Wiadomość:",
        placeholder: `Witam...`,
        textarea: 1,
        idName: "message",
      },
    ];

    const linkData_EN = [
      {
        header: "First name:",
        placeholder: `Your name`,
        idName: `firstName`,
      },
      {
        header: "Last name:",
        placeholder: `Your lastname`,
        idName: `lastName`,
      },
      {
        header: "Email:",
        placeholder: `Your email`,
        idName: `email`,
      },
      {
        header: "Phone:",
        placeholder: `Enter phone number`,
        idName: `phone`,
      },
      {
        header: "Preferred contact time:",
        placeholder: `Choose`,
        dropdown: 1,
        idName: `calendar`,
      },
      {
        header: "Company or organization name:",
        placeholder: `Complete`,
        idName: `companyname`,
      },
      {
        header: "Message:",
        placeholder: "Hello...",
        textarea: 1,
        idName: "message",
      },
    ];

    const linkData_DE = [
      {
        header: "Vorname:",
        placeholder: `Ihr Vorname:`,
        idName: "firstName",
      },
      {
        header: "Nachname:",
        placeholder: `Ihr Nachname`,
        idName: "lastName",
      },
      {
        header: "E-mail:",
        placeholder: `Ihr E-mail`,
        idName: "email",
      },
      {
        header: "Rufnummer:",
        placeholder: `Rufnummer eingeben`,
        idName: "phone",
      },
      {
        header: "Bevorzugte Kontaktzeit:",
        placeholder: `Wählen Sie aus`,
        dropdown: 1,
        idName: "calendar",
      },
      {
        header: "Name des Unternehmens oder der Organisation:",
        placeholder: `Vollständige`,
        idName: "companyname",
      },
      {
        header: "Nachricht:",
        placeholder: `Hallo...`,
        textarea: 1,
        idName: "message",
      },
    ];

    let linkData;
    this.props.localeState == "pl-PL"
      ? (linkData = linkData_PL)
      : this.props.localeState == "en-US"
      ? (linkData = linkData_EN)
      : (linkData = linkData_DE);
    return (
      <React.Fragment>
        <BreadCrumbs />
        <div
          className="OnePageWrapper"
          id="onePage"
          // onScroll={() => this.onScroll}
        >
          <Main />
          <Goal />
          <AboutPage />
          <Quality />
          <CustomMap localeState={this.props.localeState} />
          <Form linkData={linkData} localeState={this.props.localeState} />
        </div>
      </React.Fragment>
    );
  }
}

export default OnePage;

import React, { Component } from "react";
import PropTypes from "prop-types";
import "./CustomCard.scss";

import { Accordion, Card } from "react-bootstrap";

class CustomCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chosenCard: 0,
    };
  }
  render() {
    return (
      <Accordion defaultActiveKey={0}>
        {this.props.data
          ? this.props.data.map((value, index) => {
              return (
                <Accordion.Item eventKey={index}>
                  <Accordion.Header
                    onClick={() => {
                      this.state.chosenCard === index
                        ? this.setState({ chosenCard: null })
                        : this.setState({ chosenCard: index });
                    }}
                    className={
                      this.state.chosenCard === index ? "active-header" : null
                    }
                  >
                    <div className="p-xl accordion-small-header">
                      {value.jobName}
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="p-md card-text-container">
                      <p className="card-text-blue">Miejsce pracy:</p>
                      <p className="card-text">{value.jobPlace}</p>
                    </div>

                    <div className="p-md card-text-container">
                      <p className="card-text-blue">Wymiar czasu pracy:</p>
                      <p className="card-text">{value.jobTime}</p>
                    </div>
                    <div className="p-md card-text-container">
                      <p className="card-text-blue">Rodzaj umowy:</p>
                      <p className="card-text">{value.jobContract}</p>
                    </div>
                    <p className="p-md card-text-desc">{value.message}</p>
                    <p className="p-md card-text-blue">Oferujemy:</p>
                    {value.jobOfferPoints.map((item) => {
                      return <p className="p-md card-text-point">{item}</p>;
                    })}
                    <p className="p-md card-text-blue">Wymagania:</p>
                    {value.jobRequirePoints.map((item) => {
                      return <p className="p-md card-text-point">{item}</p>;
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })
          : null}
      </Accordion>
    );
  }
}

export default CustomCard;

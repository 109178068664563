import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./CustomMap.scss";
import map_PL from "../../assets/images/world_PL.svg";
import map_EN from "../../assets/images/world_EN.svg";
import map_DE from "../../assets/images/world_DE.svg";

import Separator from "../../components/Separator/Separator";
import translate from "../../lang/translate";

class CustomMap extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let language = this.props.localeState;
    return (
      <div className="CustomMap" id="customMap">
        <div className="CustomMap-title h-md">{translate("map.header")}</div>
        <img
          className="Map-image"
          src={
            language == "pl-PL" ? map_PL : language == "de-DE" ? map_DE : map_EN
          }
        />
      </div>
    );
  }
}

export default CustomMap;

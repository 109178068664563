import React, { Component } from "react";
import "./ListInput.scss";
import ReservationWidget from "../ReservationWidget";
import Select from "react-select";
import translate from "../../lang/translate";

class ListInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      options: [],
    };
  }

  componentDidMount = () => {
    if (this.props.jobTitles != null)
      this.props.jobTitles.map((item) => {
        this.setState({
          options: [...this.state.options, { value: item, label: item }],
        });
      });
  };

  render() {
    return (
      <div
        className={
          this.props.textarea
            ? this.props.index % 2 !== 0
              ? "list-input textarea second-col"
              : "list-input textarea"
            : this.props.index % 2 !== 0
            ? "list-input second-col"
            : "list-input"
        }
      >
        <p className="p-xs list-input__header">{this.props.header}</p>
        {this.props.dropdown ? (
          this.props.select == "select" ? (
            this.props.add && this.props.add == "add" ? (
              <ReservationWidget
                name="addJob"
                placeholder="Dodaj punkt"
                onChange={(e) => this.props.handleChange(e, this.props.idName)}
                idName={this.props.idName}
                value={this.state.value}
                resetChoice={this.props.resetChoice}
                add="add"
                points={this.props.points}
              />
            ) : this.props.time ? (
              <ReservationWidget
                name="job"
                placeholder={"Wybierz stanowisko pracy"}
                onChange={(e) => this.props.handleChange(e, this.props.idName)}
                idName={this.props.idName}
                value={this.state.value}
                resetChoice={this.props.resetChoice}
                jobTitles={this.props.jobTime}
              />
            ) : (
              <ReservationWidget
                name="job"
                placeholder={this.props.placeholder}
                onChange={(e) => this.props.handleChange(e, this.props.idName)}
                idName={this.props.idName}
                value={this.state.value}
                resetChoice={this.props.resetChoice}
                jobTitles={this.props.jobTitles}
              />
            )
          ) : (
            <ReservationWidget
              name="timePreference"
              placeholder={this.props.placeholder}
              localeState={this.props.localeState}
              onChange={(e) => this.props.handleChange(e, this.props.idName)}
              idName={this.props.idName}
              value={this.state.value}
              resetChoice={this.props.resetChoice}
            />
          )
        ) : this.props.textarea ? (
          <textarea
            className="list-input__content"
            placeholder={this.props.placeholder}
            onChange={(e) => this.props.handleChange(e, this.props.idName)}
          />
        ) : (
          <input
            className="list-input__input"
            placeholder={this.props.placeholder}
            onChange={(e) => this.props.handleChange(e, this.props.idName)}
            type={
              this.props.type && this.props.type == "number" ? "number" : "text"
            }
          />
        )}
      </div>
    );
  }
}

export default ListInput;

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./AdminPanel.scss";
import Form from "../Form/Form";
import Layout from "../../components/Layout";
import ReservationWidget from "../../components/ReservationWidget/ReservationWidget";
import Button from "../../components/Button";
import { NavLink, Navigate } from "react-router-dom";
import LanguageButton from "../../components/LanguageButton/LanguageButton";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import translate from "../../lang/translate";
import { Accordion, Card } from "react-bootstrap";

const axios = require("axios");
var md5 = require("md5");

class AdminPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.interval = null;
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    messageValues: {
      email: "",
      emailInput: "",
      emailToDelete: "",
      jobToDelete: "",
      folderNameInput: "",

      folderToDelete: "",
      mainFolderToDelete: "",
      subFolderToDelete: "",

      folderToAddTo: "",
      mainFolderToAddTo: "",
      subFolderToAddTo: "",

      folderToShare: "",
      folderToChangeAccess: "",
      emailToChangeAccess: "",
    },

    userData: null,
    jobData: null,
    folderData: null,
    subFolderData: null,
    folderToDeleteData: null,
    folderForUserShareData: null,
    folderForChangeAccessData: null,
    subFolderToDeleteData: null,
    selectedFile: null,
    selectedNewFile: null,
    canSend: false,
    redirect: "",
    confirmDelete: false,
    confirmFolderChange: false,
    confirmJobDelete: false,
    confirmSuperAdmin: false,
    confirmAdmin: false,

    isSuperAdmin: false,
    confirmDeleteFolder: false,

    folderListDataToAddTo: [],
    folderNamesToAddTo: [],
    addToMainFolder: false,
    addToAccordionOpen: true,

    folderListDataToDelete: [],
    folderNamesToDelete: [],
    toDeleteAccordionOpen: true,

    folderListDataToChange: [],
    folderNamesToChange: [],

    folderListDataToShare: [],
    folderNamesToShare: [],

    folderListDataToUpload: [],
    folderNamesToUpload: [],
    fileListDataToUpload: null,
    sendFilesAccordionOpen: true,

    selectedCategory: 0,
  });

  componentDidMount = () => {
    this.props.changeUrl("/admin-panel");
    NotificationManager.success(translate("n.logged-in"));
    var self = this;

    const queryParams = new URLSearchParams(window.location.search);
    const mailTockenParam = queryParams.get("mail");
    const { pathname } = window.location;
    const paths = pathname.split("/").filter((entry) => entry !== "");
    const lastPath = paths[paths.length - 1];
    const secondLastPart = paths[paths.length - 2];

    let data = {
      mailToken: lastPath,
      secondUrlPart: secondLastPart,
    };
    const initform = axios
      .post("/check-user-login", {
        data,
      })
      .then(function (response) {
        if (response.data.ok) {
          self.props.changeIsAdmin(response.data.isAdmin);
          self.props.changeMailToken(response.data.mailToken);
          if (response.data.isSuperAdmin) {
            self.setState({ isSuperAdmin: response.data.isSuperAdmin });
          }
        } else {
          NotificationManager.error(translate("n.download-data-error"));
          self.setState({
            redirect: "/login",
          });
        }
      })
      .catch(function (error) {
        NotificationManager.error(translate("n.download-data-error"));
      });

    this.adminGetUsers();
    this.adminGetJobs();

    data = {
      folderName: "./directory-catalog/",
      initLoad: true,
    };

    this.updateFolderListShare(false, 0);
    this.updateFolderListAddTo(false, 0);
    this.updateFolderListDelete(false, 0);
    this.updateFolderListChange(false, 0);
    this.updateFolderListUpload(false, 0);

    this.interval = setInterval(() => this.adminGetUsers(), 60000);
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  adminGetJobs = () => {
    let self = this;
    const form = axios
      .post("/admin-get-jobs", {})
      .then(function (response) {
        if (response.data.ok) {
          self.setState({ jobData: response.data });
        } else {
          NotificationManager.error(translate("n.download-data-error"));

          self.setState({ redirect: "/login" });
        }
      })
      .catch(function (error) {
        NotificationManager.error(translate("n.download-data-error"));
      });
  };

  adminGetUsers = () => {
    let self = this;
    const form = axios
      .post("/admin-get-users", {})
      .then(function (response) {
        if (response.data.ok) {
          self.setState({ userData: response.data });
        } else {
          NotificationManager.error(translate("n.download-data-error"));

          self.setState({ redirect: "/login" });
        }
      })
      .catch(function (error) {
        NotificationManager.error(translate("n.download-data-error"));
      });
  };

  getFolderList = (data) => {
    let self = this;
    const form = axios
      .post("/get-folder-list", {
        data,
      })
      .then(function (response) {
        if (response.data.ok) {
          let operationOnFolderData = response.data.arr.filter(
            (element) => element != "/"
          );
          self.setState({
            folderData: response.data,
            folderToDeleteData: operationOnFolderData,
            folderForUserShareData: operationOnFolderData,
            folderForChangeAccessData: operationOnFolderData,
          });
        } else {
          NotificationManager.error(translate("n.download-data-error"));
          self.setState({ redirect: "/login" });
        }
      })
      .catch(function (error) {
        NotificationManager.error(translate("n.download-data-error"));
      });
  };

  loadJobs = (val) => {
    this.setState({ jobData: val });
  };

  onFileChange = (e) => {
    // Update the state

    this.setState({ selectedFile: e.target.files[0] }, () => {});
  };

  onFileNewChange = (e) => {
    // Update the state

    this.setState({ selectedNewFile: e.target.files[0] });
  };

  deleteUser = (e) => {
    let self = this;
    const data = {
      email: this.state.messageValues.emailToDelete,
      sendEmail: true,
    };
    if (this.state.messageValues.emailToDelete) {
      const form = axios
        .post("/delete-user", {
          data,
        })
        .then(function (response) {
          if (response.data.ok) {
            NotificationManager.success(translate("n.user-deleted"));
            self.setState(
              {
                userData: response.data,
                confirmDelete: false,
                messageValues: {
                  ...self.state.messageValues,
                  emailToDelete: "",
                },
              },
              () => {
                const emailToDeleteValue =
                  document.getElementById("emailToDelete");
                if (emailToDeleteValue) {
                  emailToDeleteValue.value = "";
                }
                self.adminGetUsers();
              }
            );
          } else {
            NotificationManager.error(translate("n.user-delete-error"));
            self.setState({ redirect: "/login" });
          }
        })
        .catch(function (error) {
          NotificationManager.error(translate("n.user-delete-error"));
        });
    } else {
      NotificationManager.error(translate("n.user-delete-error"));
      self.setState({
        confirmDelete: false,
      });
    }
  };

  deleteJob = (e) => {
    let self = this;
    const data = {
      job: this.state.messageValues.jobToDelete,
    };
    if (this.state.messageValues.jobToDelete) {
      const form = axios
        .post("/delete-job", {
          data,
        })
        .then(function (response) {
          if (response.data.ok) {
            NotificationManager.success(translate("n.offer-deleted"));
            self.setState(
              {
                jobData: response.data,
                confirmJobDelete: false,
                messageValues: {
                  ...self.state.messageValues,
                  jobToDelete: "",
                },
              },
              () => {
                const firstNameInput = document.getElementById("jobToDelete");
                if (firstNameInput) {
                  firstNameInput.value = "";
                }
                self.adminGetJobs();
              }
            );
          } else {
            NotificationManager.error(translate("n.offer-delete-error"));
            self.setState({ redirect: "/login" });
          }
          self.adminGetJobs();
        })
        .catch(function (error) {
          self.setState(
            {
              confirmJobDelete: false,
              messageValues: {
                ...self.state.messageValues,
                jobToDelete: "",
              },
            },
            () => {
              const firstNameInput = document.getElementById("jobToDelete");
              if (firstNameInput) {
                firstNameInput.value = "";
              }
            }
          );
          NotificationManager.error(translate("n.offer-delete-error"));
        });
    } else {
      NotificationManager.error(translate("n.offer-delete-error"));
      self.setState({
        confirmJobDelete: false,
        messageValues: {
          ...self.state.messageValues,
          jobToDelete: "",
        },
      });
    }
  };

  checkUser = (e) => {
    let self = this;

    if (
      this.state.folderNamesToShare.length < 2 &&
      this.state.confirmSuperAdmin == false &&
      this.state.confirmAdmin == false
    ) {
      self.setState(
        {
          canSend: false,
          messageValues: {
            ...self.state.messageValues,
            email: "",
            folderToShare: "",
          },
          confirmAdmin: false,
          confirmSuperAdmin: false,
        },
        () => {
          const firstNameInput = document.getElementById("email");
          if (firstNameInput) {
            firstNameInput.value = "";
          }
          const folderToShareValue = document.getElementById("folderToShare");
          if (folderToShareValue) {
            folderToShareValue.value = "";
          }
        }
      );

      NotificationManager.error(translate("n.invitation-send-error"));
    } else {
      const data = {
        email: this.state.messageValues.emailInput,
        sendEmail: true,
        confirmAdmin: this.state.confirmSuperAdmin
          ? true
          : this.state.confirmAdmin,
        confirmSuperAdmin: this.state.confirmSuperAdmin,
        folderToShare: this.state.folderNamesToShare[1],
      };
      const form = axios
        .post("/check-user", {
          data,
        })
        .then(function (response) {
          if (response.data.ok) {
            self.setState({ canSend: true });
            const form2 = axios
              .post("/api-form-invite", {
                data,
              })
              .then(function (response) {
                if (response.data.ok) {
                  NotificationManager.success(translate("n.invitation-sent"));
                } else {
                  NotificationManager.error(
                    translate("n.invitation-send-error")
                  );
                }

                self.setState(
                  {
                    canSend: false,
                    messageValues: {
                      ...self.state.messageValues,
                      email: "",
                      folderToShare: "",
                    },
                    confirmAdmin: false,
                    confirmSuperAdmin: false,
                  },
                  () => {
                    const firstNameInput = document.getElementById("email");
                    if (firstNameInput) {
                      firstNameInput.value = "";
                    }
                    self.reloadValues();

                    self.adminGetUsers();
                  }
                );
              })
              .catch(function (error) {
                self.setState(
                  {
                    canSend: false,
                    messageValues: {
                      ...self.state.messageValues,
                      emailInput: "",
                    },
                    confirmAdmin: false,
                    consent: false,
                  },
                  () => {
                    const firstNameInput = document.getElementById("email");
                    if (firstNameInput) {
                      firstNameInput.value = "";
                    }
                    self.reloadValues();

                    self.adminGetUsers();
                  }
                );
                NotificationManager.error(translate("n.invitation-send-error"));
              });
          } else {
            NotificationManager.error(translate("n.user-is-in-base"));
            self.setState(
              {
                canSend: false,
                messageValues: {
                  ...self.state.messageValues,
                  email: "",
                  folderToShare: "",
                },
                confirmAdmin: false,
                confirmSuperAdmin: false,
              },
              () => {
                const firstNameInput = document.getElementById("email");
                if (firstNameInput) {
                  firstNameInput.value = "";
                }
                self.reloadValues();

                self.adminGetUsers();
              }
            );
          }
        })
        .catch(function (error) {
          NotificationManager.error(translate("n.user-is-in-base"));
          self.setState(
            {
              canSend: false,
              messageValues: {
                ...self.state.messageValues,
                email: "",
                folderToShare: "",
              },
              confirmAdmin: false,
              confirmSuperAdmin: false,
            },
            () => {
              const firstNameInput = document.getElementById("email");
              if (firstNameInput) {
                firstNameInput.value = "";
              }
              self.reloadValues();
              self.adminGetUsers();
            }
          );
        });
    }
  };

  handleAddFolder = () => {
    let self = this;

    if (
      this.state.addToMainFolder &&
      this.state.messageValues.folderNameInput
    ) {
      const data = {
        folderNameInput: this.state.messageValues.folderNameInput,
        folderToAddTo: "./directory-catalog/",
      };

      const form = axios
        .post("/addFolder", {
          data,
        })
        .then(function (response) {
          if (response.data.ok) {
            NotificationManager.success(translate("n.folder-add-success"));
          } else {
            NotificationManager.error(translate("n.folder-add-error"));
          }
          self.setState(
            {
              messageValues: {
                ...self.state.messageValues,
                folderNameInput: "",
                addToMainFolder: false,
              },
            },
            () => {
              let data = {
                folderName: "./directory-catalog/",
                initLoad: true,
                addToMainFolder: false,
              };
              self.reloadValues();
            }
          );
        })
        .catch(function (error) {
          NotificationManager.error(translate("n.folder-add-error"));
          self.reloadValues();
        });
    } else {
      let newFolderToAddTo = "";

      for (let i = 0; i < this.state.folderNamesToAddTo.length; i++) {
        newFolderToAddTo += this.state.folderNamesToAddTo[i] + "/";
      }

      const data = {
        folderNameInput: this.state.messageValues.folderNameInput,
        folderToAddTo: newFolderToAddTo,
      };

      const firstform = axios
        .post("/check-folder", {
          data,
        })
        .then(function (response) {
          if (response.data.ok) {
            const form = axios
              .post("/addFolder", {
                data,
              })
              .then(function (response) {
                if (response.data.ok) {
                  NotificationManager.success(
                    translate("n.folder-add-success")
                  );
                } else {
                  NotificationManager.error(translate("n.folder-add-error"));
                }
                self.setState(
                  {
                    messageValues: {
                      ...self.state.messageValues,
                      folderNameInput: "",
                      addToMainFolder: false,
                    },
                  },
                  () => {
                    let data = {
                      folderName: "./directory-catalog/",
                      initLoad: true,
                    };
                    self.reloadValues();
                  }
                );
              })
              .catch(function (error) {
                NotificationManager.error(translate("n.folder-add-error"));
              });
          } else {
            NotificationManager.info(translate("n.folder-exists"));
            self.setState({
              messageValues: {
                ...self.state.messageValues,
                folderNameInput: "",
              },
            });
          }
        })
        .catch(function (error) {
          NotificationManager.error(translate("n.folder-add-error"));
        });
    }
    this.setState(
      {
        messageValues: {
          folderNameInput: "",
          folderToAddTo: "",
          addToMainFolder: false,
        },
      },
      () => {
        const folderToAddValue = document.getElementById("folderToAddTo");
        if (folderToAddValue) {
          folderToAddValue.value = "";
        }
        const subFolderListValue = document.getElementById("subFolderList");
        if (subFolderListValue) {
          subFolderListValue.value = "";
        }
        self.reloadValues();
      }
    );
  };

  handleDeleteFolder = () => {
    let self = this;

    let newFolder = "";

    for (let i = 0; i < this.state.folderNamesToDelete.length; i++) {
      newFolder += this.state.folderNamesToDelete[i] + "/";
    }

    if (this.state.folderNamesToDelete.length != 1) {
      let data = {
        mainFolderToDelete:
          this.state.folderNamesToDelete[
            this.state.folderNamesToDelete.length - 1
          ],
        folderName: newFolder,
      };

      const form = axios
        .post("/deleteFolder", {
          data,
        })
        .then(function (response) {
          if (response.data.ok) {
            NotificationManager.success(translate("n.folder-delete-success"));
          } else {
            NotificationManager.error(translate("n.folder-delete-error"));
          }

          self.setState(
            {
              confirmDeleteFolder: false,
            },
            () => {
              data = {
                folderName: "./directory-catalog/",
                initLoad: true,
              };
              self.reloadValues();
            }
          );
        })
        .catch(function (error) {
          NotificationManager.error(translate("n.folder-delete-error"));
          self.setState(
            {
              confirmDeleteFolder: false,
              messageValues: {
                ...self.state.messageValues,
                folderToDelete: "",
                mainFolderToDelete: "",
                subFolderToDelete: "",
              },
            },
            () => {
              self.reloadValues();
            }
          );
        });

      const folderToDeleteValue = document.getElementById("folderToDelete");
      if (folderToDeleteValue) {
        folderToDeleteValue.value = "";
      }
      const subFolderToDeleteValue =
        document.getElementById("subFolderToDelete");
      if (subFolderToDeleteValue) {
        subFolderToDeleteValue.value = "";
      }
    } else {
      NotificationManager.error(translate("n.folder-delete-error"));
    }
  };

  handleLogout = () => {
    let self = this;
    const form = axios
      .post("/logout")
      .then(function (response) {
        if (response.data.ok)
          NotificationManager.success(translate("n.logged-out"));

        self.setState({
          redirect: "/login",
        });
      })
      .catch(function (error) {
        NotificationManager.error(translate("n.logout-error"));
      });
  };

  handleChange = (event, name) => {
    this.setState({
      messageValues: {
        ...this.state.messageValues,
        [name]: event.target.value,
      },
    });
  };

  changeUsersFolderAccess = () => {
    let data = {
      emailToChangeFolder: this.state.messageValues.emailToChangeAccess,
      folderName: this.state.folderNamesToChange[1],
    };

    let self = this;

    if (
      this.state.messageValues.emailToChangeAccess != "" &&
      this.state.folderNamesToChange[1] != ""
    ) {
      const form = axios
        .post("/change-folder-access", {
          data,
        })
        .then(function (response) {
          if (response.data.ok) {
            self.setState(
              {
                confirmFolderChange: false,
                messageValues: {
                  ...self.state.messageValues,
                  emailToChangeAccess: "",
                },
              },
              () => {
                const secondElementValue = document.getElementById(
                  "emailToChangeAccess"
                );
                if (secondElementValue) {
                  secondElementValue.value = "";
                }
                self.reloadValues();
              }
            );
            NotificationManager.success(
              translate("admin.change-folder-success")
            );
          } else {
            NotificationManager.error(translate("admin.change-folder-error"));
            self.setState({ redirect: "/login" });
          }
        })
        .catch(function (error) {
          NotificationManager.error(translate("admin.change-folder-error"));
          self.setState(
            {
              confirmFolderChange: false,
              messageValues: {
                ...self.state.messageValues,
                emailToChangeAccess: "",
              },
            },
            () => {
              const secondElementValue = document.getElementById(
                "emailToChangeAccess"
              );
              if (secondElementValue) {
                secondElementValue.value = "";
              }
              self.reloadValues();
            }
          );
        });
    } else {
      NotificationManager.error(translate("admin.change-folder-error"));
      self.setState(
        {
          confirmFolderChange: false,
          messageValues: {
            ...self.state.messageValues,
            emailToChangeAccess: "",
          },
        },
        () => {
          const secondElementValue = document.getElementById(
            "emailToChangeAccess"
          );
          if (secondElementValue) {
            secondElementValue.value = "";
          }
          self.reloadValues();
        }
      );
    }
  };

  updateFolderListAddTo = (e, levelIndex) => {
    let self = this;
    let newFolderNames = [];
    let newFolderListData = [];
    let newFolderName = "";

    for (let i = levelIndex; i < this.state.folderListDataToAddTo.length; i++) {
      let elementToClear = document.getElementById(`mainFolderToAddTo_no${i}`);
      if (elementToClear) {
        elementToClear.value = "";
      }
    }

    if (levelIndex == 0) {
      newFolderNames.push("directory-catalog");
      newFolderName += "/directory-catalog";
    }

    for (let i = 0; i < levelIndex; i++) {
      newFolderListData.push(this.state.folderListDataToAddTo[i]);
    }

    for (let i = 0; i < levelIndex; i++) {
      newFolderNames.push(this.state.folderNamesToAddTo[i]);

      newFolderName += "/" + this.state.folderNamesToAddTo[i];
    }

    if (e && e.target && e.target.value) {
      newFolderNames.push(e.target.value);
      newFolderName += "/" + e.target.value;
    }

    let data = {
      folderName: newFolderName,
    };
    const form = axios
      .post("/get-folder-list", {
        data,
      })
      .then(function (response) {
        if (response.data.arr && response.data.arr.length) {
          newFolderListData.push(response.data.arr);
        }

        self.setState({
          folderListDataToAddTo: newFolderListData,
          folderNamesToAddTo: newFolderNames,
        });
      })
      .catch(function (error) {
        NotificationManager.error(translate("n.download-data-error"));
      });
  };

  updateFolderListDelete = (e, levelIndex) => {
    let self = this;
    let newFolderNames = [];
    let newFolderListData = [];
    let newFolderName = "";

    for (
      let i = levelIndex;
      i < this.state.folderListDataToDelete.length;
      i++
    ) {
      let elementToClear = document.getElementById(`mainFolderToDelete_no${i}`);
      if (elementToClear) {
        elementToClear.value = "";
      }
    }

    if (levelIndex == 0) {
      newFolderNames.push("directory-catalog");
      newFolderName += "/directory-catalog";
    }

    for (let i = 0; i < levelIndex; i++) {
      newFolderListData.push(this.state.folderListDataToDelete[i]);
    }

    for (let i = 0; i < levelIndex; i++) {
      newFolderNames.push(this.state.folderNamesToDelete[i]);

      newFolderName += "/" + this.state.folderNamesToDelete[i];
    }

    if (e && e.target && e.target.value) {
      newFolderNames.push(e.target.value);
      newFolderName += "/" + e.target.value;
    }

    let data = {
      folderName: newFolderName,
    };
    const form = axios
      .post("/get-folder-list", {
        data,
      })
      .then(function (response) {
        if (response.data.arr && response.data.arr.length) {
          newFolderListData.push(response.data.arr);
        } else {
          if (levelIndex == 0) {
            self.setState({ addToMainFolder: true });
          }
        }

        self.setState({
          folderListDataToDelete: newFolderListData,
          folderNamesToDelete: newFolderNames,
        });
      })
      .catch(function (error) {
        NotificationManager.error(translate("n.download-data-error"));
      });
  };

  updateFolderListShare = (e, levelIndex) => {
    let self = this;
    let newFolderNames = [];
    let newFolderListData = [];
    let newFolderName = "";

    for (let i = levelIndex; i < this.state.folderListDataToShare.length; i++) {
      let elementToClear = document.getElementById(`mainFolderToShare_no${i}`);
      if (elementToClear) {
        elementToClear.value = "";
      }
    }

    if (levelIndex == 0) {
      newFolderNames.push("directory-catalog");
      newFolderName += "/directory-catalog";
    }

    for (let i = 0; i < levelIndex; i++) {
      newFolderListData.push(this.state.folderListDataToShare[i]);
    }

    for (let i = 0; i < levelIndex; i++) {
      newFolderNames.push(this.state.folderNamesToShare[i]);

      newFolderName += "/" + this.state.folderNamesToShare[i];
    }

    if (e && e.target && e.target.value) {
      newFolderNames.push(e.target.value);
      newFolderName += "/" + e.target.value;
    }

    if (levelIndex == 0) {
      let data = {
        folderName: newFolderName,
      };
      const form = axios
        .post("/get-folder-list", {
          data,
        })
        .then(function (response) {
          if (response.data.arr && response.data.arr.length) {
            newFolderListData.push(response.data.arr);
          }

          self.setState({
            folderListDataToShare: newFolderListData,
            folderNamesToShare: newFolderNames,
          });
        })
        .catch(function (error) {
          NotificationManager.error(translate("n.download-data-error"));
        });
    } else if (levelIndex == 1) {
      this.setState({
        folderNamesToShare: newFolderNames,
      });
    }
  };

  updateFolderListChange = (e, levelIndex) => {
    let self = this;
    let newFolderNames = [];
    let newFolderListData = [];
    let newFolderName = "";

    for (
      let i = levelIndex;
      i < this.state.folderListDataToChange.length;
      i++
    ) {
      let elementToClear = document.getElementById(`mainFolderToChange_no${i}`);
      if (elementToClear) {
        elementToClear.value = "";
      }
    }

    if (levelIndex == 0) {
      newFolderNames.push("directory-catalog");
      newFolderName += "/directory-catalog";
    }

    for (let i = 0; i < levelIndex; i++) {
      newFolderListData.push(this.state.folderListDataToChange[i]);
    }

    for (let i = 0; i < levelIndex; i++) {
      newFolderNames.push(this.state.folderNamesToChange[i]);

      newFolderName += "/" + this.state.folderNamesToChange[i];
    }

    if (e && e.target && e.target.value) {
      newFolderNames.push(e.target.value);
      newFolderName += "/" + e.target.value;
    }

    if (levelIndex == 0) {
      let data = {
        folderName: newFolderName,
      };
      const form = axios
        .post("/get-folder-list", {
          data,
        })
        .then(function (response) {
          if (response.data.arr && response.data.arr.length) {
            newFolderListData.push(response.data.arr);
          }

          self.setState({
            folderListDataToChange: newFolderListData,
            folderNamesToChange: newFolderNames,
          });
        })
        .catch(function (error) {
          NotificationManager.error(translate("n.download-data-error"));
        });
    } else if (levelIndex == 1) {
      this.setState({
        folderNamesToChange: newFolderNames,
      });
    }
  };

  reloadValues = () => {
    this.setState(
      {
        folderListDataToAddTo: [],
        folderNamesToAddTo: [],
        addToMainFolder: false,

        folderListDataToDelete: [],
        folderNamesToDelete: [],

        folderListDataToChange: [],
        folderNamesToChange: [],

        folderListDataToShare: [],
        folderNamesToShare: [],

        folderListDataToUpload: [],
        folderNamesToUpload: [],
      },
      () => {
        for (let i = 0; i < this.state.folderNamesToAddTo.length - 1; i++) {
          let inputVal = document.getElementById(`mainFolderToAddTo_no${i}`);
          inputVal.value = "";
        }

        for (let i = 0; i < this.state.folderNamesToDelete.length - 1; i++) {
          let inputVal = document.getElementById(`mainFolderToDelete_no${i}`);
          inputVal.value = "";
        }

        for (let i = 0; i < this.state.folderNamesToShare.length - 1; i++) {
          let inputVal = document.getElementById(`mainFolderToShare_no${i}`);
          inputVal.value = "";
        }

        for (let i = 0; i < this.state.folderNamesToChange.length - 1; i++) {
          let inputVal = document.getElementById(`mainFolderToChange_no${i}`);
          inputVal.value = "";
        }

        for (let i = 0; i < this.state.folderNamesToUpload.length - 1; i++) {
          let inputVal = document.getElementById(`mainFolderToSend_no${i}`);
          inputVal.value = "";
        }

        this.updateFolderListAddTo(false, 0);
        this.updateFolderListDelete(false, 0);
        this.updateFolderListShare(false, 0);
        this.updateFolderListChange(false, 0);
        this.updateFolderListUpload(false, 0);
      }
    );
  };

  updateFolderListUpload = (e, levelIndex) => {
    let self = this;
    let newFolderNames = [];
    let newFolderListData = [];
    let newFolderName = "";

    for (
      let i = levelIndex;
      i < this.state.folderListDataToUpload.length;
      i++
    ) {
      let elementToClear = document.getElementById(`mainFolderToSend_no${i}`);
      elementToClear.value = "";
    }

    if (levelIndex == 0) {
      newFolderNames.push("directory-catalog");
      newFolderName += "/directory-catalog";
    }

    for (let i = 0; i < levelIndex; i++) {
      newFolderListData.push(this.state.folderListDataToUpload[i]);
    }

    for (let i = 0; i < levelIndex; i++) {
      newFolderNames.push(this.state.folderNamesToUpload[i]);

      newFolderName += "/" + this.state.folderNamesToUpload[i];
    }

    if (e && e.target && e.target.value) {
      newFolderNames.push(e.target.value);
      newFolderName += "/" + e.target.value;
    }

    let data = {
      folderName: newFolderName,
    };
    const form = axios
      .post("/get-folder-list", {
        data,
      })
      .then(function (response) {
        if (response.data.arr && response.data.arr.length) {
          newFolderListData.push(response.data.arr);
        }

        let newFileToDownload = "";

        for (let i = 0; i < newFolderNames.length; i++) {
          newFileToDownload += newFolderNames[i] + "/";
        }

        self.setState({
          folderListDataToUpload: newFolderListData,
          folderNamesToUpload: newFolderNames,
          pathToDownload: newFileToDownload,
        });
      })
      .catch(function (error) {
        NotificationManager.error(translate("n.download-data-error"));
      });
  };

  handleFileSubmit = () => {
    const data = new FormData();

    let dirPath = "/";

    for (let i = 0; i < this.state.folderNamesToUpload.length; i++) {
      dirPath += this.state.folderNamesToUpload[i] + "/";
    }
    data.append("dir", dirPath);
    if (Array.isArray(this.state.selectedFiles)) {
      this.state.selectedFiles.forEach((file) => {
        data.append("files", file);
      });
    }

    let today = new Date();
    let date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    let time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let dateTime = date + " " + time;

    data.append("date", dateTime);

    const fifthNameInput = document.getElementById("report");
    var self = this;

    if (dirPath) {
      const formv2 = axios
        .post("/upLoadFile", data)
        .then((response) => {
          self.setState(
            {
              selectedFiles: null,
              emailInput: "",
              mainFolder: "",
              subFolder: "",
              pathToDownload: "",
            },
            () => {
              if (response.data.ok) {
                NotificationManager.success(translate("n.file-added"));
              } else {
                NotificationManager.error(translate("n.add-file-error"));
              }
              if (fifthNameInput) {
                fifthNameInput.value = null;
              }

              self.reloadValues();
            }
          );
        })
        .catch((error) => {
          NotificationManager.error(translate("n.add-file-error"));
          if (fifthNameInput) {
            fifthNameInput.value = null;
          }

          self.reloadValues();
        });
    } else {
      NotificationManager.error(translate("n.add-file-error"));
      if (fifthNameInput) {
        fifthNameInput.value = null;
      }

      self.reloadValues();
    }
  };

  onFileChange = (e) => {
    let fileListFromTarget = [];
    for (let i = 0; i < e.target.files.length; i++) {
      fileListFromTarget.push(e.target.files[i]);
    }

    this.setState({ selectedFiles: fileListFromTarget });
  };

  render() {
    const jobContracts = ["Umowa o pracę", "Umowa zlecenie"];
    const jobTime = ["Pełen etat", "Pół etatu", "Praca w weekendy"];
    const linkFormData_PL = [
      {
        header: "Nazwa stanowiska:",
        placeholder: `Stanowiska`,
        idName: "jobName",
      },
      {
        header: "Miejsce pracy:",
        placeholder: `Miasto/miejscowość`,
        idName: "jobPlace",
      },

      {
        header: "Wymiar czasu pracy:",
        placeholder: `Wymiar czasu pracy`,
        idName: "jobTime",
        dropdown: 1,
        select: "select",
        time: true,
      },
      {
        header: "Rodzaj umowy:",
        placeholder: `Wybierz rodzaj umowy`,
        idName: "jobContract",
        dropdown: 1,
        select: "select",
      },

      {
        header: "Punkty ofery:",
        placeholder: `Dodaj punkt ofery`,
        idName: "jobOfferPoints",
        dropdown: 1,
        select: "select",
        add: "add",
        points: [],
      },
      {
        header: "Punkty wymagań:",
        placeholder: `Dodaj punkt wymagań`,
        idName: "jobRequirePoints",
        dropdown: 1,
        select: "select",
        add: "add",
        points: [],
      },
      {
        header: "Opis stanowiska:",
        placeholder: `...`,
        textarea: 1,
        idName: "message",
      },
    ];

    const linkFormData_EN = [
      {
        header: "Job title:",
        placeholder: `Job title`,
        idName: `jobName`,
      },
      {
        header: "Job Place:",
        placeholder: `City`,
        idName: `jobPlace`,
      },

      {
        header: "Job time dimension:",
        placeholder: `Job time dimension`,
        idName: `jobTime`,
        dropdown: 1,
        select: "select",
        time: true,
      },
      {
        header: "Contract type:",
        placeholder: `Select contract type`,
        idName: `jobContract`,
        dropdown: 1,
        select: "select",
      },

      {
        header: "Offer points:",
        placeholder: `Add an offer point`,
        idName: `jobOfferPoints`,
        dropdown: 1,
        select: "select",
        add: "add",
        points: [],
      },
      {
        header: "Requirement points:",
        placeholder: `Add Requirement Point`,
        idName: `jobRequirePoints`,
        dropdown: 1,
        select: "select",
        add: "add",
        points: [],
      },
      {
        header: "Job description:",
        placeholder: `...`,
        textarea: 1,
        idName: `message`,
      },
    ];

    const linkFormData_DE = [
      {
        header: "Job Title:",
        placeholder: `Job Title`,
        idName: `jobName`,
      },
      {
        header: "Arbeitsplatz:",
        placeholder: `Gemeinde/Stadt`,
        idName: `jobPlace`,
      },

      {
        header: "Arbeitszeit:",
        placeholder: `Arbeitszeit`,
        idName: `jobTime`,
        dropdown: 1,
        select: "select",
        time: true,
      },
      {
        header: "Vertragsart:",
        placeholder: `Wählen Sie die Art des Vertrags`,
        idName: `jobContract`,
        dropdown: 1,
        select: "select",
      },

      {
        header: "Stellenanzeigen:",
        placeholder: `Auftragspunkt hinzufügen`,
        idName: `jobOfferPoints`,
        dropdown: 1,
        select: "select",
        add: "add",
        points: [],
      },
      {
        header: "Arbeitsortanforderungen:",
        placeholder: `Einen Arbeitspunkt hinzufügen`,
        idName: `jobRequirePoints`,
        dropdown: 1,
        select: "select",
        add: "add",
        points: [],
      },
      {
        header: "Stellenbeschreibung:",
        placeholder: `...`,
        textarea: 1,
        idName: `message`,
      },
    ];

    let linkFormData;
    this.props.localeState == "pl-PL"
      ? (linkFormData = linkFormData_PL)
      : this.props.localeState == "en-US"
      ? (linkFormData = linkFormData_EN)
      : (linkFormData = linkFormData_DE);

    if (this.state.redirect != "") {
      return <Navigate replave to={this.state.redirect} />;
    }
    return (
      <div className="AdminPanelWrapper">
        <div className="admin-category-buttons">
          <div
            className="admin-button"
            onClick={() => this.setState({ selectedCategory: 0 })}
          >
            <Button
              buttonStyle={
                this.state.selectedCategory === 0 ? "filled" : "grey"
              }
              title={translate("admin.folderCategory")}
              className="category-button"
            />
          </div>
          <div
            className="admin-button"
            onClick={() => this.setState({ selectedCategory: 1 })}
          >
            <Button
              buttonStyle={
                this.state.selectedCategory === 1 ? "filled" : "grey"
              }
              title={translate("admin.userCategory")}
              className="category-button"
            />
          </div>
          <div
            className="admin-button"
            onClick={() => this.setState({ selectedCategory: 2 })}
          >
            <Button
              buttonStyle={
                this.state.selectedCategory === 2 ? "filled" : "grey"
              }
              title={translate("admin.jobCategory")}
              className="category-button"
            />
          </div>
        </div>
        <div className="admin-background-img">
          <div className="admin-title h-md">{translate("admin.header")}</div>

          {this.state.userData &&
          this.state.userData.ok &&
          this.state.jobData &&
          this.state.jobData.ok ? (
            <div className="admin-container">
              {this.state.selectedCategory === 0 ? (
                <React.Fragment>
                  <div className="add-report-container">
                    <form>
                      <div className="admin-small-title h-sm">
                        {translate("admin.user-send-header")}
                      </div>
                      <Accordion defaultActiveKey={0}>
                        <Accordion.Item eventKey={0}>
                          <Accordion.Header
                            onClick={() => {
                              this.setState({
                                sendFilesAccordionOpen:
                                  !this.state.sendFilesAccordionOpen,
                              });
                            }}
                            className={
                              this.state.sendFilesAccordionOpen
                                ? "admin-header-accordion active-header"
                                : "admin-header-accordion"
                            }
                          >
                            <div className="p-md accordion-small-header admin">
                              {translate("admin.path-label")}
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="admin">
                            {this.state.folderListDataToUpload &&
                            this.state.folderListDataToUpload.length
                              ? this.state.folderListDataToUpload.map(
                                  (element, index) => {
                                    return (
                                      <div className="admin-list-input">
                                        <p className="p-xs admin-list-input__header">
                                          {translate("admin.user-send-folder")}
                                        </p>
                                        <ReservationWidget
                                          name="admin"
                                          placeholder={
                                            this.props.localeState == "pl-PL"
                                              ? "Wybierz folder"
                                              : this.props.localeState ==
                                                "en-US"
                                              ? "Choose a directory"
                                              : "Ordner auswählen"
                                          }
                                          onChange={(e) =>
                                            this.updateFolderListUpload(
                                              e,
                                              index + 1
                                            )
                                          }
                                          idName={`mainFolderToSend_no${index}`}
                                          value={
                                            this.state.folderNamesToUpload[
                                              index + 1
                                            ]
                                          }
                                          resetChoice={this.props.resetChoice}
                                          jobTitles={
                                            this.state.folderListDataToUpload
                                              ? this.state
                                                  .folderListDataToUpload[index]
                                              : null
                                          }
                                          admin="admin"
                                        />
                                      </div>
                                    );
                                  }
                                )
                              : null}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className="kontakt__attachment-container user-attachment-container">
                        <p className="kontakt__attachment-desc user__attachment-desc p-xs">
                          {translate("admin.user-send-attachment")}
                        </p>
                        <input
                          type="file"
                          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf"
                          className="kontakt__attachment-button"
                          onChange={this.onFileChange}
                          id="report"
                          multiple
                        />
                      </div>
                    </form>
                    <div
                      className="admin-button"
                      onClick={() => this.handleFileSubmit()}
                      enctype="multipart/form-data"
                    >
                      <Button
                        buttonStyle="filled"
                        title={translate("admin.user-send-button")}
                      />
                    </div>
                  </div>
                  <div className="admin-add-folder-container">
                    <div className="admin-small-title h-sm">
                      {translate("admin.add-folder-header")}
                    </div>
                    {!this.state.addToMainFolder ? (
                      <Accordion defaultActiveKey={0}>
                        <Accordion.Item eventKey={0}>
                          <Accordion.Header
                            onClick={() => {
                              this.setState({
                                addToAccordionOpen:
                                  !this.state.addToAccordionOpen,
                              });
                            }}
                            className={
                              this.state.addToAccordionOpen
                                ? "admin-header-accordion active-header"
                                : "admin-header-accordion"
                            }
                          >
                            <div className="p-md accordion-small-header admin">
                              {translate("admin.path-label")}
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="admin">
                            {this.state.folderListDataToAddTo &&
                            this.state.folderListDataToAddTo.length
                              ? this.state.folderListDataToAddTo.map(
                                  (element, index) => {
                                    return (
                                      <div className="admin-list-input">
                                        <p className="p-xs admin-list-input__header">
                                          {translate("admin.user-send-folder")}
                                        </p>
                                        <ReservationWidget
                                          name="admin"
                                          placeholder={
                                            this.props.localeState == "pl-PL"
                                              ? "Wybierz folder"
                                              : this.props.localeState ==
                                                "en-US"
                                              ? "Choose a directory"
                                              : "Ordner auswählen"
                                          }
                                          onChange={(e) =>
                                            this.updateFolderListAddTo(
                                              e,
                                              index + 1
                                            )
                                          }
                                          idName={`mainFolderToAddTo_no${index}`}
                                          value={
                                            this.state.folderNamesToAddTo[
                                              index + 1
                                            ]
                                          }
                                          resetChoice={this.props.resetChoice}
                                          jobTitles={
                                            this.state.folderListDataToAddTo
                                              ? this.state
                                                  .folderListDataToAddTo[index]
                                              : null
                                          }
                                          admin="admin"
                                        />
                                      </div>
                                    );
                                  }
                                )
                              : null}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    ) : null}

                    <div className="admin-list-input">
                      <p className="p-xs admin-list-input__header">
                        {translate("admin.user-send-folder")}
                      </p>
                      <input
                        className="admin-list-input__input"
                        placeholder={
                          this.props.localeState == "pl-PL"
                            ? "Nazwa nowego folderu"
                            : this.props.localeState == "en-US"
                            ? "Neue Ordner Name"
                            : "New directory name"
                        }
                        onChange={(e) =>
                          this.handleChange(e, "folderNameInput")
                        }
                        id="addFolder"
                        value={this.state.messageValues.folderNameInput}
                      />
                    </div>

                    <div className="kontakt__checkbox-container">
                      <div
                        className={
                          this.state.addToMainFolder
                            ? "kontakt__custom-checkbox active"
                            : "kontakt__custom-checkbox"
                        }
                        onClick={() => {
                          this.setState({
                            addToMainFolder: !this.state.addToMainFolder,
                          });
                        }}
                      />
                      <span className="p-2xs kontakt__checkbox-description">
                        {translate("admin.add-to-mainFolder")}
                      </span>
                    </div>

                    <div
                      className="admin-button"
                      onClick={() => this.handleAddFolder()}
                      enctype="multipart/form-data"
                    >
                      <Button
                        buttonStyle="filled"
                        title={translate("admin.add-folder-button-label")}
                      />
                    </div>
                  </div>
                  {this.state.isSuperAdmin ? (
                    <div className="admin-delete-folder-container">
                      <div className="admin-small-title h-sm">
                        {translate("admin.folder-delete-header")}
                      </div>
                      <Accordion defaultActiveKey={0}>
                        <Accordion.Item eventKey={0}>
                          <Accordion.Header
                            onClick={() => {
                              this.setState({
                                toDeleteAccordionOpen:
                                  !this.state.toDeleteAccordionOpen,
                              });
                            }}
                            className={
                              this.state.toDeleteAccordionOpen
                                ? "admin-header-accordion active-header"
                                : "admin-header-accordion"
                            }
                          >
                            <div className="p-md accordion-small-header admin">
                              {translate("admin.path-label")}
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="admin">
                            {this.state.folderListDataToDelete &&
                            this.state.folderListDataToDelete.length
                              ? this.state.folderListDataToDelete.map(
                                  (element, index) => {
                                    return (
                                      <div className="admin-list-input">
                                        <p className="p-xs admin-list-input__header">
                                          {translate("admin.user-send-folder")}
                                        </p>
                                        <ReservationWidget
                                          name="admin"
                                          placeholder={
                                            this.props.localeState == "pl-PL"
                                              ? "Wybierz folder"
                                              : this.props.localeState ==
                                                "en-US"
                                              ? "Choose a directory"
                                              : "Ordner auswählen"
                                          }
                                          onChange={(e) =>
                                            this.updateFolderListDelete(
                                              e,
                                              index + 1
                                            )
                                          }
                                          idName={`mainFolderToDelete_no${index}`}
                                          value={
                                            this.state.folderNamesToDelete[
                                              index + 1
                                            ]
                                          }
                                          resetChoice={this.props.resetChoice}
                                          jobTitles={
                                            this.state.folderListDataToDelete
                                              ? this.state
                                                  .folderListDataToDelete[index]
                                              : null
                                          }
                                          admin="admin"
                                        />
                                      </div>
                                    );
                                  }
                                )
                              : null}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div
                        className="admin-button"
                        onClick={() => {
                          !this.state.confirmDeleteFolder
                            ? this.setState({ confirmDeleteFolder: true }, () =>
                                NotificationManager.info(
                                  translate("n.folder-delete-confirm")
                                )
                              )
                            : this.handleDeleteFolder();
                        }}
                        enctype="multipart/form-data"
                      >
                        <Button
                          buttonStyle="filled"
                          title={
                            !this.state.confirmDeleteFolder
                              ? translate("admin.delete-folder-button-label")
                              : translate("n.folder-delete-confirm")
                          }
                        />
                      </div>
                    </div>
                  ) : null}
                </React.Fragment>
              ) : this.state.selectedCategory === 1 ? (
                <React.Fragment>
                  <div className="admin-email-container">
                    <div className="admin-small-title h-sm">
                      {translate("admin.invite-header")}
                    </div>
                    <div className="admin-list-input">
                      <p className="p-xs admin-list-input__header">E-mail:</p>
                      <input
                        className="admin-list-input__input"
                        placeholder="E-mail"
                        onChange={(e) => this.handleChange(e, "emailInput")}
                        id="email"
                      />
                    </div>

                    {this.state.isSuperAdmin ? (
                      <React.Fragment>
                        <div className="kontakt__checkbox-container">
                          <div
                            className={
                              this.state.confirmSuperAdmin
                                ? "kontakt__custom-checkbox active"
                                : "kontakt__custom-checkbox"
                            }
                            onClick={() => {
                              this.setState({
                                confirmSuperAdmin:
                                  !this.state.confirmSuperAdmin,
                                confirmAdmin: false,
                              });
                            }}
                          />
                          <span className="p-2xs kontakt__checkbox-description">
                            {translate("admin.invite-checkbox-super")}
                          </span>
                        </div>

                        <div className="kontakt__checkbox-container">
                          <div
                            className={
                              !this.state.confirmSuperAdmin
                                ? this.state.confirmAdmin
                                  ? "kontakt__custom-checkbox active"
                                  : "kontakt__custom-checkbox"
                                : "kontakt__custom-checkbox disabled"
                            }
                            onClick={() => {
                              this.setState({
                                confirmAdmin: !this.state.confirmAdmin,
                                confirmSuperAdmin: false,
                              });
                            }}
                          />
                          <span className="p-2xs kontakt__checkbox-description">
                            {translate("admin.invite-checkbox")}
                          </span>
                        </div>
                      </React.Fragment>
                    ) : null}

                    {this.state.confirmAdmin == false &&
                    this.state.confirmSuperAdmin == false
                      ? this.state.folderListDataToShare &&
                        this.state.folderListDataToShare.length
                        ? this.state.folderListDataToShare.map(
                            (element, index) => {
                              return (
                                <div className="admin-list-input">
                                  <p className="p-xs admin-list-input__header">
                                    {translate("admin.user-send-folder")}
                                  </p>
                                  <ReservationWidget
                                    name="admin"
                                    placeholder={
                                      this.props.localeState == "pl-PL"
                                        ? "Wybierz folder"
                                        : this.props.localeState == "en-US"
                                        ? "Choose a directory"
                                        : "Ordner auswählen"
                                    }
                                    onChange={(e) =>
                                      this.updateFolderListShare(e, index + 1)
                                    }
                                    idName={`mainFolderToShare_no${index}`}
                                    value={
                                      this.state.folderNamesToShare[index + 1]
                                    }
                                    resetChoice={this.props.resetChoice}
                                    jobTitles={
                                      this.state.folderListDataToShare
                                        ? this.state.folderListDataToShare[
                                            index
                                          ]
                                        : null
                                    }
                                    admin="admin"
                                  />
                                </div>
                              );
                            }
                          )
                        : null
                      : null}

                    <div
                      className="admin-button"
                      onClick={(e) => this.checkUser(e)}
                    >
                      <Button
                        buttonStyle="filled"
                        title={translate("admin.invite-button")}
                      />
                    </div>
                  </div>
                  <div className="admin-email-container">
                    <div className="admin-small-title h-sm">
                      {translate("admin.delete-user-header")}
                    </div>
                    <div className="admin-list-input">
                      <p className="p-xs admin-list-input__header">
                        {translate("admin.delete-user-user")}
                      </p>
                      <ReservationWidget
                        name="admin"
                        placeholder={
                          this.props.localeState == "pl-PL"
                            ? "Wybierz użytkownika"
                            : this.props.localeState == "en-US"
                            ? "Choose a user"
                            : "Benutzer auswählen"
                        }
                        onChange={(e) => this.handleChange(e, "emailToDelete")}
                        idName={"emailToDelete"}
                        value={this.state.messageValues.emailToDelete}
                        resetChoice={this.props.resetChoice}
                        jobTitles={this.state.userData.arr}
                        admin="admin"
                      />
                    </div>
                    <div
                      className="admin-button"
                      onClick={(e) =>
                        !this.state.confirmDelete &&
                        this.state.messageValues.emailToDelete != ""
                          ? this.setState({ confirmDelete: true }, () =>
                              NotificationManager.info(
                                translate("n.sure-to-delete-user")
                              )
                            )
                          : this.deleteUser(e)
                      }
                    >
                      <Button
                        buttonStyle="filled"
                        title={
                          !this.state.confirmDelete
                            ? translate("admin.delete-user-delete")
                            : translate("admin.delete-user-delete-confirm")
                        }
                      />
                    </div>
                  </div>
                  <div className="admin-email-container">
                    <div className="admin-small-title h-sm">
                      {translate("admin.change-folder-button-label")}
                    </div>
                    <div className="admin-list-input">
                      <p className="p-xs admin-list-input__header">
                        {translate("admin.user-send-user")}
                      </p>
                      <ReservationWidget
                        name="admin"
                        placeholder={
                          this.props.localeState == "pl-PL"
                            ? "Wybierz użytkownika"
                            : this.props.localeState == "en-US"
                            ? "Choose a user"
                            : "Benutzer auswählen"
                        }
                        onChange={(e) =>
                          this.handleChange(e, "emailToChangeAccess")
                        }
                        idName={"emailToChangeAccess"}
                        value={this.state.messageValues.emailToChangeAccess}
                        resetChoice={this.props.resetChoice}
                        jobTitles={this.state.userData.arr}
                        admin="admin"
                      />
                    </div>
                    {this.state.folderListDataToChange &&
                    this.state.folderListDataToChange.length
                      ? this.state.folderListDataToChange.map(
                          (element, index) => {
                            return (
                              <div className="admin-list-input">
                                <p className="p-xs admin-list-input__header">
                                  {translate("admin.user-send-folder")}
                                </p>
                                <ReservationWidget
                                  name="admin"
                                  placeholder={
                                    this.props.localeState == "pl-PL"
                                      ? "Wybierz folder"
                                      : this.props.localeState == "en-US"
                                      ? "Choose a directory"
                                      : "Ordner auswählen"
                                  }
                                  onChange={(e) =>
                                    this.updateFolderListChange(e, index + 1)
                                  }
                                  idName={`mainFolderToChange_no${index}`}
                                  value={
                                    this.state.folderNamesToChange[index + 1]
                                  }
                                  resetChoice={this.props.resetChoice}
                                  jobTitles={
                                    this.state.folderListDataToChange
                                      ? this.state.folderListDataToChange[index]
                                      : null
                                  }
                                  admin="admin"
                                />
                              </div>
                            );
                          }
                        )
                      : null}
                    <div
                      className="admin-button"
                      onClick={(e) =>
                        !this.state.confirmFolderChange
                          ? this.setState({ confirmFolderChange: true }, () =>
                              NotificationManager.info(
                                translate("admin.change-folder-button-confirm")
                              )
                            )
                          : this.changeUsersFolderAccess()
                      }
                    >
                      <Button
                        buttonStyle="filled"
                        title={
                          !this.state.confirmFolderChange
                            ? translate("admin.change-folder-button-label")
                            : translate("admin.change-folder-button-confirm")
                        }
                      />
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* <div className="kontakt__attachment-container user-attachment-container">
                  <p className="kontakt__attachment-desc user__attachment-desc p-xs">
                    {`Załącznik z plikiem z ofertami pracy: `}
                  </p>
                  <input
                    type="file"
                    accept=""
                    className="kontakt__attachment-button"
                    onChange={this.onFileNewChange}
                    id="job"
                  />
                </div>
                <div
                  className="admin-button"
                  onClick={() => this.handleJobSubmit()}
                >
                  <Button buttonStyle="filled" title="Wyślij plik" />
                </div> */}
                  <div className="admin-job-container">
                    <div className="admin-small-title h-sm">
                      {translate("admin.add-job-offer-header")}
                    </div>

                    <Form
                      localeState={this.props.localeState}
                      title="addJob"
                      jobTitles={
                        jobContracts != null
                          ? jobContracts.map((item) => {
                              return item;
                            })
                          : []
                      }
                      jobTime={
                        jobTime != null
                          ? jobTime.map((item) => {
                              return item;
                            })
                          : []
                      }
                      linkData={linkFormData}
                      noMargin={true}
                      loadJobs={this.loadJobs}
                      adminGetJobs={this.adminGetJobs}
                    />
                  </div>
                  <div className="admin-email-container">
                    <div className="admin-small-title h-sm">
                      {translate("admin.delete-job-offer-header")}
                    </div>
                    <div className="admin-list-input">
                      <p className="p-xs admin-list-input__header">
                        {translate("admin.delete-job-offer-offer")}
                      </p>
                      <ReservationWidget
                        name="admin"
                        placeholder={
                          this.props.localeState == "pl-PL"
                            ? "Wybierz ofertę pracy"
                            : this.props.localeState == "en-US"
                            ? "Choose a job-offer"
                            : "Jobangebot auswählen"
                        }
                        onChange={(e) => this.handleChange(e, "jobToDelete")}
                        idName={"jobToDelete"}
                        value={this.state.messageValues.jobToDelete}
                        resetChoice={this.props.resetChoice}
                        jobTitles={
                          this.state.jobData ? this.state.jobData.arr : []
                        }
                        admin="admin"
                      />
                    </div>
                    <div
                      className="admin-button"
                      onClick={(e) =>
                        !this.state.confirmJobDelete &&
                        this.state.messageValues.jobToDelete != ""
                          ? this.setState({ confirmJobDelete: true }, () =>
                              NotificationManager.info(
                                translate("n.sure-to-delete-offer")
                              )
                            )
                          : this.deleteJob(e)
                      }
                    >
                      <Button
                        buttonStyle="filled"
                        title={
                          !this.state.confirmJobDelete
                            ? translate("admin.delete-job-offer-delete")
                            : translate("admin.delete-job-offer-delete-confirm")
                        }
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          ) : null}
          <div className="button-wrapper" onClick={() => this.handleLogout()}>
            <Button
              buttonStyle="filled"
              title={translate("user.logout")}
              className="login-button"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AdminPanel;

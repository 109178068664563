import { LOCALES } from "../locales";

export default {
  [LOCALES.ENGLISH]: {
    /*HEADER*/
    "header.about-us": "ABOUT US",
    "header.ows": " general terms of sale",
    "header.contact-us": "CONTACT",
    "header.quality": "QUALITY CONTROL",
    "header.job": "JOB",
    "header.home": "HOME",
    "header.logout": "LOGOUT",
    "header.user-panel": "DIRECTORY LIST",
    "header.admin-panel": "ADMIN PLATFORM",

    /*FOOTER*/
    "footer.company": "COMPANY",
    "footer.goal": "Our goal",
    "footer.about-us": "About us",
    "footer.quality": "QUALITY CONTROL",
    "footer.quality-delivery": "Supplier quality control",
    "footer.quality-end": "Final quality control",
    "footer.job": "JOB",
    "footer.job-offers": "Job offers",
    "footer.for-clients": "FOR CUSTOMERS",
    "footer.map":
      "The world map was downloaded from the website (link below), modifications: change of colours and marking of countries",

    /*BREADCRUMBS*/
    "breadcrumbs.goal": "OUR GOAL",
    "breadcrumbs.about-us": "ABOUT US",
    "breadcrumbs.quality": "QUALITY CONTROL",
    "breadcrumbs.contact-us": "CONTACT",

    /*SEPARATOR*/
    "separator.goal": "OUR GOAL",
    "separator.about-us": "ABOUT US",
    "separator.quality": "QUALITY CONTROL",
    "separator.contact-us": "CONTACT",
    "separator.job": "JOB OFFERS",
    "separator.job-apply": "Apply for a job",

    /*MAIN*/
    "main.first-slide": `SQA&R is a venture started in 2000, based on the passion and many years of experience of the owner Przemek Kowalewski. After many years of work in the automotive industry, we started cooperation with tycoons "on our own"
    Since then, we have established cooperation with many companies. Representing them in many countries. Poland, Russia, Czech Republic, Slovakia, Germany ...`,
    "main.second-slide": `SQA&R is an investment in many years of experience in cooperation with OEM manufacturers from the automotive industry.
    Vast knowledge of engine construction.
    Knowing areas of potential non-compliance
    Innovation and commitment to problem solving.
    The ability of advanced analysis and detection of root causes of non-compliance.`,
    "main.third-slide": `SQA&R is a medium-sized enterprise that operates quickly and dynamically, unhampered by a large organizational structure, with the shortest path from idea to decision.`,

    /*GOAL*/
    "goal.header1":
      "Striving for customer satisfaction by precisely defining their expectations.",
    "goal.header2":
      "Improving our own processes in order to develop the competences of our staff who will be able to meet customer expectations.",
    "goal.header3": "We offer our products in 3 main areas:",

    "goal.title1_1": "Supplier quality control",
    "goal.title1_2": "Final quality control",
    "goal.title2_1": "100% inspection services",
    "goal.title2_2": "Quality services",
    "goal.title2_3": "OUTSOURCING",

    "goal.desc1_1":
      "We support our customers in developing the quality of suppliers within their company.",
    "goal.desc1_2":
      "We specialise in quality control services for components and finished products.",
    "goal.desc2_1":
      "100% inspection and / or repair of components. Controled Shipping Level 2",
    "goal.desc2_2":
      "temporary posting of an employee (to the customer or his supplier)",
    "goal.desc2_3": "Specialists, engineers, residents",
    "goal.button": "More about SQA&R",

    /*ABOUT US*/
    "about.header1": "We offer this service when:",
    "about.point1.1": "the supplier has delivered non-compliant products",
    "about.point1.2":
      "the customer requires 100% control of manufactured parts",
    "about.point1.3":
      "the process is unstable and requires additional controls",
    "about.point1.4": "you launch a new product and need temporarily employees",

    "about.header2": "We can carry out this service:",
    "about.point2.1": "at the supplier",
    "about.point2.2": "at the customer",
    "about.point2.3": "at our facilities",
    "about.point2.4":
      "at outsourced facilities (e.g. laboratory, external warehouse)",

    "about.header3": "You can rely on us because:",
    "about.point3.1": "our lead time is minimal",
    "about.point3.2": "provides 24/7 service",
    "about.point3.3": "our inspectors are experienced, oriented in quality ",
    "about.point3.4": "our employees work based on an employment contract",
    "about.point3.5":
      "we support the inspection process with engineering knowledge",
    "about.point3.6": "we work in accordance with ISO 9001: 2000",

    "about.header4": "Benefits of cooperation with SQA&R:",
    "about.point4.1":
      "motivated employees (employment contract, occasional and group bonuses)",
    "about.point4.2":
      "reduction of costs related to human resources (recruitment, service, training, clothing etc.)",
    "about.point4.3":
      "cooperation with selected employees, without increasing your „headcount”",
    "about.point4.4":
      "legal liability and accident insurance on the side of the service provider",
    "about.point4.5": "experienced staff",

    /*QUALITY-CONTROL*/
    "quality.header1": "SUPPLIER QUALITY CONTROL",
    "quality.desc1":
      "We are aware of how important it is to have on-time deliveries of parts and additional controls to avoid disrupting production. With our services, our customers can be confident that the tools needed for proper production operation are in the hands of professionals. This provides stability, safety, and punctuality to the customer, as well as allowing them to save time by improving business operations and indicators.",
    "quality.header2": "FINAL INSPECTION",
    "quality.desc2":
      "Final inspection allows for a quick response to production problems without having to stop the production line and involve employees. It also allows for monitoring the production process and reducing costs associated with potential claims from the end customer. In each of our projects, we provide technical support and deliver a detailed report of the services performed.",

    /*MAP*/
    "map.header": "Countries in which we currently represent our clients",

    /*CONTACT*/
    "contact.check-box-desc":
      "I agree to the collection, processing and use of my personal data by SQAR engineering solutions",
    "contact.button": "Send message",
    "contact.welcome": "We invite you to contact us regarding cooperation",
    "contact.hour": "hour",
    "contact.select": "Select",

    /*JOB*/
    "job.attachment": "CV attachment(doc, docx, pdf formats accepted): ",
    "job.desc1":
      "SQA&R is a rapidly growing part of an international capital consortium. In our company, we recruit people who passionately and enthusiastically carry out their daily work. It is their work and effort that brings so many successes to our company.",
    "job.header1": "Job Offers - We're Looking for People with Vision",
    "job.desc2": "We're looking for people who:",
    "job.point1": "want to develop their technological skills",
    "job.point2": "derive satisfaction from working on diverse projects",
    "job.point3": "like to think and create new solutions",
    "job.point4":
      "want to work in an environment that promotes professionalization of work",
    "job.desc3":
      "Our work environment allows for individual development, contributing to professional successes and career growth. If you want to be part of such a team, express your interest in collaborating.",
    "job.header2": "We employ people for:",

    /*LOGIN*/
    "login.header": "Authentication required",
    "login.password": "Password:",
    "login.password-placeholder": "Password",
    "login.login": "login",
    "login.forgot-password": "Forgot password?",
    "login.dont-have-account": "Don't have an account? Email your request to:",

    /*RDB*/
    "rdb.header1": "Access to online reports",
    "rdb.desc1":
      "With the RDB application, you have access to up-to-date reports of your work. From now on you will have all reports at hand, no matter where you are. You can download reports in XLSX format.",
    "rdb.header2": "Data available 24/7",
    "rdb.desc2":
      "You have total access to your reports, anytime. Your reports will be updated daily.",
    "rdb.header3": "Easy to use application",
    "rdb.desc3":
      "Your reports will be stored in an application that is very intuitive and easy to use, its main purpose is to make your work easier.",

    /*SEND-EMAIL*/
    "send-email.header": "Enter email address to reset password: ",
    "send-email.send": "Send",

    /*RESET-PASSWORD*/
    "reset-password.header": "Reset password for address: ",
    "reset-password.new-password": "New password:",
    "reset-password.repeat-password": "Repeat password:",
    "reset-password.button": "Reset password",

    /*REGISTER-USER*/
    "register.header": "Create account for: ",
    "register.password": "Password:",
    "register.button": "Register",

    /*USER*/
    "user.header": "AVAILABLE REPORTS",
    "user.click-download": "Click on the report to download it",
    "user.click-delete": "Click on the report to delete it",
    "user.click-download-button": "Download",
    "user.click-delete-button": "Delete",
    "user.rep-num": "Report number",
    "user.rep-name": "file name",
    "user.rep-admin": "Administrator",
    "user.rep-date": "Date",
    "user.logout": "Logout",
    "user.folder-choose": "Select directory from list",
    "user.no-reports": "No reports available...",

    /*ADMIN*/
    "admin.header": "Admin panel",
    "admin.user-send-header":
      "Select one of the users to whom you want to send the report",
    "admin.user-send-user": "User:",
    "admin.user-send-folder": "Directory:",
    "admin.user-send-folder-list": "Directory-list:",
    "admin.user-send-sub-folder": "Sub-directory:",
    "admin.user-send-attachment": "Report attachment(xslx format): ",
    "admin.user-send-button": "Send Report",

    "admin.invite-header": "Send invitation to register",
    "admin.invite-checkbox": "Give the user admin rights",
    "admin.invite-checkbox-super": "Give the user superadmin rights",
    "admin.invite-button": "Send message",

    "admin.delete-user-header": "Delete user",
    "admin.delete-user-user": "User:",
    "admin.delete-user-delete": "Delete user",
    "admin.delete-user-delete-confirm": "Confirm user deletion",

    "admin.add-job-offer-header": "Add job offer",

    "admin.delete-job-offer-header": "Remove job position",
    "admin.delete-job-offer-offer": "Job position:",
    "admin.delete-job-offer-delete": "Delete job offer",
    "admin.delete-job-offer-delete-confirm": "Confirm deletion of position",

    "admin.logout": "Logout",
    "admin.add-folder-header": "Add a new directory",
    "admin.folder-delete-header": "Delete directory",
    "admin.add-folder-button-label": "Add directory",
    "admin.delete-folder-button-label": "Delete directory",
    "admin.change-folder-button-label": "Change users folder",
    "admin.change-folder-button-confirm": "Confirm folder change",
    "admin.change-folder-success": "User's folder successfully changed",
    "admin.change-folder-error":
      "An error occurred while trying to change the user's folder",
    "admin.add-to-mainFolder": "Create a new customer folder",
    "admin.path-label": "Path of open folders ...",
    "admin.download-check": "Download",
    "admin.delete-check": "Delete",
    "admin.folderCategory": "Folders",
    "admin.userCategory": "Users",
    "admin.jobCategory": "Job offers",

    /*ERROR_404*/
    "404.error": "404 ERROR",
    "404.page": "Page not available",
    "404.back": "Back to home page",

    /*NOTIFICATION*/
    "n.logged-out": "Logged out.",
    "n.logout-error": "Problem during logout.",
    "n.logged-in": "Logged in successfully!",
    "n.download-data-error": "Error while downloading data.",
    "n.file-added": "Added file!",
    "n.add-file-error": "Failed to add file.",
    "n.user-deleted": "User has been deleted from the database.",
    "n.user-delete-error": "Failed to delete user.",
    "n.offer-deleted": "The post has been deleted from the database.",
    "n.offer-delete-error": "Failed to delete the post.",
    "n.invitation-sent": "An invitation was sent!",
    "n.invitation-send-error": "Failed to send invitation!",
    "n.user-is-in-base": "The user exists in the database",
    "n.sure-to-delete-user":
      "Are you sure you want to delete the selected user?",
    "n.sure-to-delete-offer":
      "Are you sure you want to delete the selected user?",
    "n.false-login-data": "Wrong login-data.",
    "n.login-error": "Problem during login.",
    "n.short-password": "Password must be at least 8 characters.",
    "n.passwords-not-equal": "Passwords are not equal.",
    "n.registration-success": "Registration was successful!",
    "n.registration-error": "Registration failed.",
    "n.password-reset-success": "Password reset successfully!",
    "n.password-reset-error": "Error while resetting password.",
    "n.reset-email-sent": "An email was sent to reset the password",
    "n.reset-email-send-error": "Error while sending email.",
    "n.download-file-error": "Error while downloading a file.",
    "n.no-reports": "No reports.",

    "n.contract-type": "contract type",
    "n.job-name": "job-name",
    "n.job-offer-points": "offer points",
    "n.job-require-points": "requirement points",
    "n.job-place": "job place",
    "n.job-time": "job time",
    "n.job-description": "job description",

    "n.consent": "you must accept consent",

    "n.name": "firstname",
    "n.lastname": "lastname",
    "n.phone": "phone",
    "n.contact-time": "preferred contact-time",
    "n.message": "message",
    "n.company-name": "company or organisation name",
    "n.job-position": "job-position",
    "n.cv": "cv attachment",

    "n.fields": "fields",
    "n.field": "field",
    "n.are-empty": " are empty",
    "n.ist-empty": " is empty",

    "n.wrong-email": "invalid email address.",

    "n.formula-sent-success": "The form has been sent.",
    "n.formula-send-error": "An error occurred while submitting the form.",

    "n.formula-sent-success": "The form has been submitted.",
    "n.formula-send-error": "An error occurred while submitting the form.",
    "n.offer-sent-success": "The post was added successfully.",
    "n.offer-send-error": "An error occurred while adding the position.",

    "n.folder-add-success": "Added folder successfully",
    "n.folder-add-error": "There was a problem while adding the folder",
    "n.folder-delete-success": "Deleted folder successfully",
    "n.folder-delete-confirm": "Confirm folder deletion",
    "n.folder-delete-error": "There was a problem while deleting the folder",
    "n.folder-exists": "The folder exists in the database",
    "n.file-delete-success": "Deleted file successfully",
    "n.file-delete-error": "There was a problem while deleting the file",
  },
};

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./JobPage.scss";
import Layout from "../../components/Layout";
import Separator from "../../components/Separator/Separator";
import CustomCard from "../../components/CustomCard";
import linkData from "../../assets/job-offers/jobs";
import Form from "../Form/Form";
import translate from "../../lang/translate";
const axios = require("axios");

const linkData_PL = [
  {
    header: "Imię:",
    placeholder: `Twoje imię`,
    idName: "firstName",
  },
  {
    header: "Nazwisko:",
    placeholder: `Twoje nazwisko`,
    idName: "lastName",
  },
  {
    header: "E-mail:",
    placeholder: `Twój e-mail`,
    idName: "email",
  },
  {
    header: "Stanowisko:",
    placeholder: `Wybierz stanowisko`,
    idName: "job",
    dropdown: 1,
    select: "select",
  },
  {
    header: "Wiadomość:",
    placeholder: `Witam...`,
    textarea: 1,
    idName: "message",
  },
];

const linkData_EN = [
  {
    header: "First name:",
    placeholder: `Your name`,
    idName: `firstName`,
  },
  {
    header: "Last name:",
    placeholder: `Your lastname`,
    idName: `lastName`,
  },
  {
    header: "Email:",
    placeholder: `Your email`,
    idName: `email`,
  },
  {
    header: "Position:",
    placeholder: `Select position`,
    idName: `job`,
    dropdown: 1,
    select: "select",
  },
  {
    header: "Message:",
    placeholder: "Hello...",
    textarea: 1,
    idName: "message",
  },
];

const linkData_DE = [
  {
    header: "Vorname:",
    placeholder: `Ihr Vorname:`,
    idName: "firstName",
  },
  {
    header: "Nachname:",
    placeholder: `Ihr Nachname`,
    idName: "lastName",
  },
  {
    header: "E-mail:",
    placeholder: `Ihr E-mail`,
    idName: "email",
  },
  {
    header: "Position:",
    placeholder: `Position auswählen`,
    idName: `job`,
    dropdown: 1,
    select: "select",
  },
  {
    header: "Nachricht:",
    placeholder: `Hallo...`,
    textarea: 1,
    idName: "message",
  },
];

class JobPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      userData: null,
    };
  }

  componentDidMount = () => {
    this.props.changeUrl("/job");
    let self = this;
    const form = axios
      .post("/api-get-jobs", {})
      .then(function (response) {
        self.setState({ userData: response.data.arr });
      })
      .catch(function (error) {});
  };

  render() {
    let linkFormData;
    this.props.localeState == "pl-PL"
      ? (linkFormData = linkData_PL)
      : this.props.localeState == "en-US"
      ? (linkFormData = linkData_EN)
      : (linkFormData = linkData_DE);
    return (
      <div className="JobPage">
        <Separator content={translate("separator.job")} />
        <div className="JobPage-desc-first p-md">
          {translate("job.desc1")}
        </div>

        <div className="JobPage-desc-title-first p-xl">
          {translate("job.header1")}
        </div>
        <div className="JobPage-text-container">
          <div className="JobPage-desc p-md">{translate("job.desc2")}</div>
          <div className="JobPage-desc-point p-md">
            {translate("job.point1")}
          </div>
          <div className="JobPage-desc-point p-md">
            {translate("job.point2")}
          </div>
          <div className="JobPage-desc-point p-md">
            {translate("job.point3")}
          </div>
          <div className="JobPage-desc-point p-md">
            {translate("job.point4")}
          </div>
          <div className="JobPage-desc p-md">
            {translate("job.desc3")}
          </div>
        </div>

        <div className="JobPage-desc-title p-xl">
          {translate("job.header2")}
        </div>
        <div className="JobPage-Accordion">
          {this.state.userData ? (
            <div className="Accordion__container">
              <CustomCard index={"0"} data={this.state.userData} />
            </div>
          ) : null}
        </div>
        <Form
          localeState={this.props.localeState}
          title="job"
          linkData={linkFormData}
          jobTitles={
            this.state.userData != null
              ? this.state.userData.map((item) => {
                  return item.jobName;
                })
              : []
          }
          attachment={true}
          noMargin={true}
        />
      </div>
    );
  }
}

export default JobPage;

import "./App.css";
import OnePage from "./pages/OnePage/OnePage";
import "./assets/scss/typography.scss";
import "./assets/scss/breakpoints.scss";
import "./assets/scss/style.scss";
import React, { PureComponent } from "react";
import { Provider } from "react-redux";
import { I18nProvider, LOCALES } from "./lang";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
  HashRouter,
} from "react-router-dom";
import Header from "./components/Header/Header";
import Kontakt from "./pages/Kontakt/Kontakt";
import JobPage from "./pages/JobPage/JobPage";
import ErrorPage from "./pages/ErrorPage";
import ScrollToTop from "./components/ScrollToTop";
import Login from "./pages/Login/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import SendEmail from "./pages/SendEmail/SendEmail";
import UserPage from "./pages/UserPage/UserPage";
import RegisterUser from "./pages/RegisterUser/RegisterUser";
import AdminPanel from "./pages/AdminPanel/AdminPanel";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

class App extends PureComponent {
  state = {
    category: null,
    hg: false,
    url: "/",
    isAdmin: false,
    mailToken: "",
    setLocale:
      navigator.language == "pl" || navigator.userLanguage == "pl"
        ? "pl-PL"
        : navigator.language == "de" || navigator.userLanguage == "de"
        ? "de-DE"
        : "en-US",
  };

  changeUrl = (url) => {
    this.setState({ url });
  };

  changeIsAdmin = (isAdmin) => {
    this.setState({ isAdmin });
  };

  changeMailToken = (mailToken) => {
    this.setState({ mailToken });
  };

  changeLocaleState = (setLocale) => {
    this.setState({ setLocale });
  };

  getLocaleState = () => {
    return this.state.setLocale;
  };

  render() {
    return (
      <I18nProvider locale={this.state.setLocale}>
        <div className="App">
          <Router>
            <Header
              changeUrl={this.changeUrl}
              urlName={this.state.url}
              isAdmin={this.state.isAdmin}
              mailToken={this.state.mailToken}
              changeLocaleState={this.changeLocaleState}
            />
            <ScrollToTop>
              <Routes>
                <Route path="*" element={<Navigate replace to="/404" />} />
                <Route
                  path="/register/:mail/:token"
                  element={
                    <RegisterUser
                      localeState={this.state.setLocale}
                      changeUrl={this.changeUrl}
                    />
                  }
                ></Route>
                <Route
                  path="/reset-password/:mail/:token"
                  element={
                    <ResetPassword
                      localeState={this.state.setLocale}
                      changeUrl={this.changeUrl}
                    />
                  }
                ></Route>
                <Route
                  path="/admin-panel/:mail"
                  element={
                    <AdminPanel
                      localeState={this.state.setLocale}
                      changeUrl={this.changeUrl}
                      changeMailToken={this.changeMailToken}
                      changeIsAdmin={this.changeIsAdmin}
                      changeLocaleState={this.changeLocaleState}
                    />
                  }
                  exact
                ></Route>

                <Route
                  path="/user/:mail"
                  element={
                    <UserPage
                      localeState={this.state.setLocale}
                      changeIsAdmin={this.changeIsAdmin}
                      changeUrl={this.changeUrl}
                      changeMailToken={this.changeMailToken}
                    />
                  }
                ></Route>

                <Route
                  path="/send-email"
                  element={
                    <SendEmail
                      localeState={this.state.setLocale}
                      changeUrl={this.changeUrl}
                    />
                  }
                ></Route>
                {/* <Route path="/login" element={<Login />} /> */}
                <Route
                  path="/404"
                  element={<ErrorPage changeUrl={this.changeUrl} />}
                />
                <Route
                  path="/job"
                  element={
                    <JobPage
                      localeState={this.state.setLocale}
                      changeUrl={this.changeUrl}
                    />
                  }
                />
                <Route
                  path="/login"
                  element={
                    <Login
                      localeState={this.state.setLocale}
                      changeUrl={this.changeUrl}
                    />
                  }
                ></Route>

                <Route
                  exact
                  path="/"
                  element={
                    <OnePage
                      changeLocaleState={this.changeLocaleState}
                      changeUrl={this.changeUrl}
                      localeState={this.state.setLocale}
                    />
                  }
                />
              </Routes>
            </ScrollToTop>
            <Kontakt urlName={this.state.url} />
          </Router>
          <NotificationContainer />
        </div>
      </I18nProvider>
    );
  }
}

export default App;

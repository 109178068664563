import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./UserPage.scss";
import linkData from "../../assets/files/userFiles";
import Layout from "../../components/Layout";
import { NavLink, Navigate } from "react-router-dom";
import ReservationWidget from "../../components/ReservationWidget/ReservationWidget";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Button from "../../components/Button";
import translate from "../../lang/translate";
import { Accordion, Card } from "react-bootstrap";

const axios = require("axios");
var md5 = require("md5");

class UserPage extends PureComponent {
  constructor(props) {
    super(props);
    this.arr = null;
    this.state = {
      redirect: "",
      userData: null,

      folderData: null,
      subFolderData: null,
      subFolderToDisplayData: null,
      mainFolderToDisplay: "",
      subFolderToDisplay: "",

      email: "",
      emailInput: "",
      selectedFiles: null,
      mainFolder: "",
      subFolder: "",
      isAdmin: false,
      loggedEmail: "",
      sharedFolder: "",

      folderListDataToUpload: [],
      folderNamesToUpload: [],
      fileListDataToUpload: null,

      folderListDataToDisplay: [],
      folderNamesToDisplay: [],
      fileListDataToDisplay: null,
      pathToDownload: "",

      deleteFiles: false,
      sendFilesAccordionOpen: true,
      showFilesAccordionOpen: true,

      downloadCheck: true,
      deleteCheck: false,

      confirmFileDelete: false,
      filePathToDelete: "",
      fileNameToDelete: "",
    };
  }

  componentDidMount = () => {
    this.props.changeUrl("/user");
    NotificationManager.success(translate("n.logged-in"));
    var self = this;

    const { pathname } = window.location;
    const paths = pathname.split("/").filter((entry) => entry !== "");
    const lastPath = paths[paths.length - 1];
    const secondLastPart = paths[paths.length - 2];

    let data = {
      mailToken: lastPath,
      secondUrlPart: secondLastPart,
    };
    const form = axios
      .post("/check-user-login", {
        data,
      })
      .then(function (response) {
        if (!response.data.ok) {
          NotificationManager.error(translate("n.download-data-error"));
          self.setState({ redirect: "/login" });
        } else {
          self.props.changeIsAdmin(response.data.isAdmin);
          self.props.changeMailToken(response.data.mailToken);
          self.setState(
            {
              isAdmin: response.data.isAdmin,
              loggedEmail: response.data.email,
              sharedFolder: response.data.sharedFolder,
            },
            () => {
              self.updateFolderListDisplay(false, 0);
            }
          );
        }
      })
      .catch(function (error) {
        NotificationManager.error(translate("n.download-data-error"));
      });
  };

  handleLogout = () => {
    let self = this;
    const form = axios
      .post("/logout")
      .then(function (response) {
        if (response.data.ok)
          NotificationManager.success(translate("n.logged-out"));

        self.setState({
          redirect: "/login",
        });
      })
      .catch(function (error) {
        NotificationManager.error(translate("n.logout-error"));
      });
  };

  handleDownload = (name, dirName) => {
    var self = this;
    if (name && dirName) {
      const data = {
        fileName: name,
        dirName: dirName,
      };
      const form = axios
        .get("/download-file")
        .then(function (response) {
          self.setState({ userData: response.data });
        })
        .catch(function (error) {
          NotificationManager.error(translate("n.download-file-error"));
        });
    } else {
      NotificationManager.error(translate("n.download-file-error"));
    }
  };

  handleChange = (event, name) => {
    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  loadFiles = () => {
    let self = this;

    let newFolderName = "";
    for (let i = 0; i < this.state.folderNamesToDisplay.length; i++) {
      newFolderName += this.state.folderNamesToDisplay[i] + "/";
    }

    if (newFolderName != "") {
      let data = {
        folderName: newFolderName,
      };

      const form = axios
        .post("/file-list", {
          data,
        })
        .then(function (response) {
          if (response.data.ok) {
            self.setState({
              fileListDataToDisplay:
                response.data && response.data.arr ? response.data.arr : null,
            });
          } else {
            NotificationManager.error(translate("n.download-data-error"));
          }
        })
        .catch(function (error) {
          NotificationManager.error(translate("n.download-data-error"));
        });
    }
  };

  updateFolderListDisplay = (e, levelIndex) => {
    let self = this;
    let newFolderNames = [];
    let newFolderListData = [];
    let newFolderName = "";

    for (
      let i = levelIndex;
      i < this.state.folderListDataToDisplay.length;
      i++
    ) {
      let elementToClear = document.getElementById(
        `mainFolderToDisplay_no${i}`
      );
      elementToClear.value = "";
    }

    if (levelIndex == 0) {
      newFolderNames.push("directory-catalog");
      newFolderName += "/directory-catalog";
    }

    for (let i = 0; i < levelIndex; i++) {
      newFolderListData.push(this.state.folderListDataToDisplay[i]);
    }

    for (let i = 0; i < levelIndex; i++) {
      newFolderNames.push(this.state.folderNamesToDisplay[i]);

      newFolderName += "/" + this.state.folderNamesToDisplay[i];
    }

    if (e && e.target && e.target.value) {
      newFolderNames.push(e.target.value);
      newFolderName += "/" + e.target.value;
    }

    let data = {
      folderName: newFolderName,
    };
    const form = axios
      .post("/get-folder-list", {
        data,
      })
      .then(function (response) {
        if (response.data.arr && response.data.arr.length) {
          newFolderListData.push(response.data.arr);
        }

        let newFileToDownload = "";

        for (let i = 0; i < newFolderNames.length; i++) {
          newFileToDownload += newFolderNames[i] + "/";
        }

        self.setState(
          {
            folderListDataToDisplay: newFolderListData,
            folderNamesToDisplay: newFolderNames,
            pathToDownload: newFileToDownload,
          },
          () => {
            self.loadFiles();
          }
        );
      })
      .catch(function (error) {
        NotificationManager.error(translate("n.download-data-error"));
      });
  };

  deleteFile = (filePath, fileName) => {
    let self = this;
    let data = {
      filePath: filePath,
      fileName: fileName,
    };
    const form = axios
      .post("/delete-file", {
        data,
      })
      .then(function (response) {
        if (response.data.ok) {
          NotificationManager.success(translate("n.file-delete-success"));
          self.loadFiles();
        } else {
          NotificationManager.error(translate("n.file-delete-error"));
        }
      })
      .catch(function (error) {
        NotificationManager.error(translate("n.file-delete-error"));
      });
    this.setState({ confirmFileDelete: false });
  };

  render() {
    if (this.state.redirect != "") {
      return <Navigate replave to={this.state.redirect} />;
    }
    return (
      <div className="UserPageWrapper">
        <div className="user-background-img">
          {/* foler list */}
          <div className="user-title h-md">{translate("user.header")}</div>
          <div className="user-title-small h-sm">
            {translate("user.folder-choose")}
          </div>

          <div className="user-list-container">
            <Accordion defaultActiveKey={0}>
              <Accordion.Item eventKey={0}>
                <Accordion.Header
                  onClick={() => {
                    this.setState({
                      showFilesAccordionOpen:
                        !this.state.showFilesAccordionOpen,
                    });
                  }}
                  className={
                    this.state.showFilesAccordionOpen
                      ? "admin-header-accordion active-header"
                      : "admin-header-accordion"
                  }
                >
                  <div className="p-md accordion-small-header admin">
                    {translate("admin.path-label")}
                  </div>
                </Accordion.Header>
                <Accordion.Body className="admin">
                  {this.state.folderListDataToDisplay &&
                  this.state.folderListDataToDisplay.length
                    ? this.state.folderListDataToDisplay.map(
                        (element, index) => {
                          return (
                            <div className="user-list-input">
                              <p className="p-xs user-list-input__header__white">
                                {translate("admin.user-send-folder")}
                              </p>
                              <ReservationWidget
                                name="admin"
                                placeholder={
                                  this.props.localeState == "pl-PL"
                                    ? "Wybierz folder"
                                    : this.props.localeState == "en-US"
                                    ? "Choose a directory"
                                    : "Ordner auswählen"
                                }
                                onChange={(e) =>
                                  this.updateFolderListDisplay(e, index + 1)
                                }
                                idName={`mainFolderToDisplay_no${index}`}
                                value={
                                  this.state.folderNamesToDisplay[index + 1]
                                }
                                resetChoice={this.props.resetChoice}
                                jobTitles={
                                  this.state.folderListDataToDisplay
                                    ? this.state.folderListDataToDisplay[index]
                                    : null
                                }
                                admin="admin"
                              />
                            </div>
                          );
                        }
                      )
                    : null}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {!this.state.deleteCheck ? (
              <div className="user-title-small h-sm">
                {translate("user.click-download")}
              </div>
            ) : (
              <div className="user-title-small h-sm">
                {translate("user.click-delete")}
              </div>
            )}

            {this.state.isAdmin ? (
              <React.Fragment>
                <div className="admin__checkbox-container">
                  <div
                    className={
                      this.state.downloadCheck
                        ? "admin__custom-checkbox active"
                        : "admin__custom-checkbox"
                    }
                    onClick={() => {
                      this.setState(
                        {
                          downloadCheck: !this.state.downloadCheck,
                        },
                        () => {
                          this.setState({
                            deleteCheck: !this.state.downloadCheck,
                          });
                        }
                      );
                    }}
                  />
                  <span className="p-2xs admin__checkbox-description">
                    {translate("admin.download-check")}
                  </span>
                </div>
                <div className="delete__container">
                  <div className="admin__checkbox-container">
                    <div
                      className={
                        this.state.deleteCheck
                          ? "admin__custom-checkbox active"
                          : "admin__custom-checkbox"
                      }
                      onClick={() => {
                        this.setState(
                          {
                            deleteCheck: !this.state.deleteCheck,
                          },
                          () => {
                            this.setState({
                              downloadCheck: !this.state.deleteCheck,
                            });
                          }
                        );
                      }}
                    />
                    <span className="p-2xs admin__checkbox-description">
                      {translate("admin.delete-check")}
                    </span>
                  </div>
                  {this.state.deleteCheck ? (
                    <div
                      className="button-wrapper admin"
                      onClick={() =>
                        this.state.deleteCheck && this.state.confirmFileDelete
                          ? this.deleteFile(
                              this.state.filePathToDelete,
                              this.state.fileNameToDelete
                            )
                          : null
                      }
                    >
                      <Button
                        buttonStyle={
                          this.state.deleteCheck && this.state.confirmFileDelete
                            ? "filled"
                            : "grey"
                        }
                        title={translate("n.file-delete-confirm-button")}
                        className="delete-button"
                      />
                    </div>
                  ) : null}
                </div>
              </React.Fragment>
            ) : null}

            <div className="user-files-container">
              <div className="user-report-grey">
                <div
                  className={
                    this.state.isAdmin ? "report-cell admin" : "report-cell"
                  }
                >
                  {translate("user.rep-num")}
                </div>
                <div
                  className={
                    this.state.isAdmin ? "report-cell admin" : "report-cell"
                  }
                >
                  {translate("user.rep-name")}
                </div>
                {this.state.isAdmin ? (
                  <React.Fragment>
                    <div
                      className={
                        this.state.isAdmin ? "report-cell admin" : "report-cell"
                      }
                    >
                      {translate("user.rep-admin")}
                    </div>
                    <div
                      className={
                        this.state.isAdmin ? "report-cell admin" : "report-cell"
                      }
                    >
                      {translate("user.rep-date")}
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
              {this.state.pathToDownload &&
              this.state.fileListDataToDisplay &&
              this.state.fileListDataToDisplay.length ? (
                this.state.fileListDataToDisplay.map((item, index) => {
                  return (
                    <a
                      className={
                        index == 0
                          ? "user-report first"
                          : index == this.state.fileListDataToDisplay.length - 1
                          ? "user-report last"
                          : "user-report"
                      }
                      href={
                        this.state.downloadCheck
                          ? "/download-file/" +
                            this.state.pathToDownload +
                            item.name
                          : null
                      }
                      target={"_blank"}
                      download={this.state.downloadCheck ? true : false}
                      onClick={() =>
                        this.state.deleteCheck
                          ? this.setState(
                              {
                                confirmFileDelete: true,
                                filePathToDelete: this.state.pathToDownload,
                                fileNameToDelete: item.name,
                              },
                              () => {
                                NotificationManager.info(
                                  translate("n.file-delete-confirm")
                                );
                              }
                            )
                          : null
                      }
                    >
                      <div
                        className={
                          this.state.isAdmin
                            ? "report-cell admin"
                            : "report-cell"
                        }
                      >
                        <div className="report-cell-value p-xs">{`${
                          index + 1
                        }.`}</div>
                      </div>
                      <div
                        className={
                          this.state.isAdmin
                            ? "report-cell admin"
                            : "report-cell"
                        }
                      >
                        <div className="report-cell-value p-xs">
                          {item.name}
                        </div>
                      </div>
                      {this.state.isAdmin ? (
                        <React.Fragment>
                          <div
                            className={
                              this.state.isAdmin
                                ? "report-cell admin"
                                : "report-cell"
                            }
                          >
                            <div className="report-cell-value p-xs">
                              {item.admin}
                            </div>
                          </div>
                          <div
                            className={
                              this.state.isAdmin
                                ? "report-cell admin"
                                : "report-cell"
                            }
                          >
                            <div className="report-cell-value p-xs">
                              {item.date}
                            </div>
                          </div>
                        </React.Fragment>
                      ) : null}

                      {/* <div className="report-date">{item.date}</div> */}
                    </a>
                  );
                })
              ) : (
                <div className="user-report-grey none">
                  <div className="report-name p-xs">
                    {translate("user.no-reports")}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* send report */}

          <div className="button-wrapper" onClick={() => this.handleLogout()}>
            <Button
              buttonStyle="filled"
              title={translate("user.logout")}
              className="login-button"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default UserPage;

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//import { Test } from './Kontakt.styles';
import "./Kontakt.scss";
import Layout from "../../components/Layout/Layout";
import logo from "../../assets/images/logo.svg";
import { NavLink } from "react-router-dom";
import translate from "../../lang/translate";
class Kontakt extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  scrollToElement(id) {
    window.scrollTo({
      top: document.getElementById(id).offsetTop - 30,
      left: 0,
      behavior: "smooth",
    });
  }

  render() {
    let lastPart = window.location.href.split("/").pop();
    return (
      <div className="kontaktWrapper" id="footer">
        <div className="first-column">
          <img src={logo} className="logo" alt="logo" />

          <div className="p-xs text-blok">
            <div className="text-container-white p-md">
              {"SQA&R ENGINEERING SOLUTIONS"}
            </div>
            <div className="text-container-white p-md">
              PRZEMYSŁAW KOWALEWSKI SPÓŁKA KOMANDYTOWA
            </div>
            <div className="text-container-white p-md">
              Polanka 7 59-243 Polanka
            </div>
            <div className="text-container-white p-md">NIP 6912562044</div>
            <div className="text-container-white p-md">Regon 522354225</div>
            <a href="mailto:office@sqar.com.pl">
              <div className="text-container gray p-md">
                email: office@sqar.com.pl
              </div>
            </a>
            <a href="tel:+48-793-919-332">
              <div className="text-container gray p-md">
                tel. +48 79 39 19 332
              </div>
            </a>
          </div>
        </div>
        {this.props.urlName && this.props.urlName == "/" ? (
          <React.Fragment>
            <div className="second-column">
              <div className="p-xs text-blok">
                <div className="text-container-title h-sm">
                  {translate("footer.company")}
                </div>
                <div
                  className="text-container p-md"
                  onClick={() =>
                    this.setState({ buttonOpen: false }, () =>
                      this.scrollToElement("goal")
                    )
                  }
                >
                  {translate("footer.goal")}
                </div>
                <div
                  className="text-container p-md"
                  onClick={() =>
                    this.setState({ buttonOpen: false }, () =>
                      this.scrollToElement("about")
                    )
                  }
                >
                  {translate("footer.about-us")}
                </div>
                <div className="text-container-title h-sm">
                  {translate("footer.quality")}
                </div>
                <div
                  className="text-container p-md"
                  onClick={() =>
                    this.setState({ buttonOpen: false }, () =>
                      this.scrollToElement("quality")
                    )
                  }
                >
                  {translate("footer.quality-delivery")}
                </div>
                <div
                  className="text-container p-md"
                  onClick={() =>
                    this.setState({ buttonOpen: false }, () =>
                      this.scrollToElement("quality")
                    )
                  }
                >
                  {translate("footer.quality-end")}
                </div>
              </div>
            </div>
            <div className="third-column">
              <div className="p-xs text-blok">
                <div className="text-container-title h-sm">
                  {translate("footer.job")}
                </div>
                <NavLink to="/job" className="text-container p-md">
                  {translate("footer.job-offers")}
                </NavLink>
                <div className="text-container-title h-sm">
                  {translate("footer.for-clients")}
                </div>
                <NavLink to="/login" className="text-container p-md">
                  RDB
                </NavLink>
              </div>
            </div>
          </React.Fragment>
        ) : null}
        {lastPart == "" ? (
          <React.Fragment>
            <div className="p-3xs svg-map desc">{translate("footer.map")}</div>
            <a
              className="p-3xs svg-map link"
              href="https://mapsvg.com/maps/world"
            >
              https://mapsvg.com/maps/world
            </a>
          </React.Fragment>
        ) : null}
        <div className="p-3xs copyright">{`Copyright © 2022 SQA&R`}</div>
      </div>
    );
  }
}

export default Kontakt;

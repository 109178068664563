import React, { Component } from "react";
import "./Button.scss";

class Button extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <button className={`button button--${this.props.buttonStyle}`}>
        <p className="p-md">{this.props.title}</p>
      </button>
    );
  }
}

export default Button;

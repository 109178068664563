import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./GreyBlock.scss";
import Button from "../Button";
import translate from "../../lang/translate";

class GreyBlock extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }
  scrollToElement(id) {
    window.scrollTo({
      top: document.getElementById(id).offsetTop - 30,
      left: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <div
        className={
          this.props.last ? "GreyBlockWrapper last" : "GreyBlockWrapper"
        }
      >
        <div className="Block-part1">
          <i className={this.props.icons[0]} />
          <div className="part1-title h-sm">{this.props.titles[0]}</div>
          <div className="part1-desc p-xs">{this.props.desc[0]}</div>
        </div>
        <div className="Block-part2">
          <i className={this.props.icons[1]} />
          <div className="part2-title h-sm">{this.props.titles[1]}</div>
          <div className="part2-desc p-xs">{this.props.desc[1]}</div>
        </div>
        <div
          className={
            this.props.titles[2] == "button" ? "Block-part3-btn" : "Block-part3"
          }
        >
          {this.props.titles[2] != "button" ? (
            <React.Fragment>
              <i className={this.props.icons[2]} />
              <div className="part3-title h-sm">{this.props.titles[2]}</div>
              <div className="part3-desc p-xs">{this.props.desc[2]}</div>
            </React.Fragment>
          ) : (
            <div onClick={() => this.scrollToElement("about")}>
              <Button
                buttonStyle="filled"
                title={translate("goal.button")}
                onClick={() => this.scrollToElement("about")}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default GreyBlock;

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./Quality.scss";
import Layout from "../../components/Layout";
import Separator from "../../components/Separator/Separator";
import translate from "../../lang/translate";

class Quality extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render() {
    return (
      <div className="QualityWrapper" id="quality">
        <Layout background="white" direction="column">
          <Separator content={translate("separator.quality")} />
          <div className="background-img">
            <div className="h-md title">{translate("quality.header1")}</div>
            <div className="text p-md">
              {translate("quality.desc1")}
            </div>
          </div>
          <div className="no-background-text">
            <div className="h-md title">{translate("quality.header2")}</div>
            <div className="text p-md">
              {translate("quality.desc2")}
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default Quality;

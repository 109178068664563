import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./Login.scss";
import Button from "../../components/Button/Button";
import Layout from "../../components/Layout";
import logo from "../../assets/images/logo.svg";
import { NavLink, Navigate } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import translate from "../../lang/translate";

const axios = require("axios");
var md5 = require("md5");

class Login extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      redirect: "",
      messageValues: {
        password: "",
        email: "",
      },
    };
  }

  componentDidMount = () => {
    this.props.changeUrl("/login");
    const firstNameInput = document.getElementById("email");
    const secondNameInput = document.getElementById("password");
    firstNameInput.value = "";
    secondNameInput.value = "";

    let self = this;
    const form = axios
      .post("/logout", {})
      .then(function (response) {})
      .catch(function (error) {
        NotificationManager.error(translate("n.login-error"));
      });
  };

  handleSubmit = () => {
    const data = {
      password: md5(this.state.messageValues.password),
      email: this.state.messageValues.email,
    };
    let self = this;
    const form = axios
      .post("/login-next-step", {
        data,
      })
      .then(function (response) {
        if (!response.data.ok)
          NotificationManager.error(translate("n.false-login-data"));

        self.setState({
          redirect: response.data.link ? response.data.link : "",
        });
      })
      .catch(function (error) {
        NotificationManager.error(translate("n.login-error"));
      });
    const firstNameInput = document.getElementById("email");
    const secondNameInput = document.getElementById("password");
    firstNameInput.value = "";
    secondNameInput.value = "";
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit();
    }
  };

  handleChange = (event, name) => {
    this.setState({
      messageValues: {
        ...this.state.messageValues,
        [name]: event.target.value,
      },
    });
  };

  render() {
    if (this.state.redirect != "") {
      return <Navigate replave to={this.state.redirect} />;
    } else
      return (
        <div className="LoginWrapper">
          <Layout background="white">
            <div className="login-background-img">
              <img src={logo} className="login-logo" alt="sqar logo" />
              <div className="login-title p-lg">
                {translate("login.header")}
              </div>

              <div className="login-list-input">
                <p className="p-xs login-list-input__header">E-mail:</p>
                <input
                  className="login-list-input__input"
                  placeholder="E-mail"
                  onChange={(e) => this.handleChange(e, "email")}
                  onKeyDown={this.handleKeyDown}
                  id="email"
                />
              </div>
              <div className="login-list-input">
                <p className="p-xs login-list-input__header">
                  {translate("login.password")}
                </p>
                <input
                  className="login-list-input__input second"
                  placeholder={
                    this.props.localeState == "pl-PL"
                      ? "Hasło"
                      : this.props.localeState == "de-DE"
                      ? "Passwort"
                      : "Password"
                  }
                  secureTextEntry={true}
                  type="password"
                  onChange={(e) => this.handleChange(e, "password")}
                  onKeyDown={this.handleKeyDown}
                  id="password"
                />
              </div>

              <div className="bottom-part">
                <div className="button-wrapper" onClick={this.handleSubmit}>
                  <Button
                    buttonStyle="filled"
                    title={translate("login.login")}
                    className="login-button"
                  />
                </div>
                <NavLink to="/send-email" className="password-reset p-2xs">
                  {translate("login.forgot-password")}
                </NavLink>
              </div>
              <div className="login-contact p-2xs">
                {translate("login.dont-have-account")}
                <a href="mailto:office@sqar.com.pl">
                  <div className="text-container grey">office@sqar.com.pl</div>
                </a>
              </div>
            </div>
            <div className="login-background-img-right">
              <div className="login-title-right p-xl  ">REPORT DATA BASE</div>
              <div className="login-container-right">
                <div className="login-desc-img-right">
                  <i className="las la-scroll p-lg" />
                  <div className="login-text-container">
                    <div className="login-desc-title-right p-md ">
                      {translate("rdb.header1")}
                    </div>
                    <div className="login-desc-right  p-2xs">
                      {translate("rdb.desc1")}
                    </div>
                  </div>
                </div>

                <div className="login-desc-img-right">
                  <i className="las la-calendar-day p-md"></i>
                  <div className="login-text-container">
                    {" "}
                    <div className="login-desc-title-right p-md ">
                      {translate("rdb.header2")}
                    </div>
                    <div className="login-desc-right p-2xs">
                      {translate("rdb.desc2")}
                    </div>
                  </div>
                </div>

                <div className="login-desc-img-right">
                  <i className="las la-laptop-code p-md"></i>
                  <div className="login-text-container">
                    <div className="login-desc-title-right p-md ">
                      {translate("rdb.header3")}
                    </div>
                    <div className="login-desc-right p-2xs">
                      {translate("rdb.desc3")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Layout>
        </div>
      );
  }
}

export default Login;

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./BreadCrumbs.scss";
import translate from "../../lang/translate";

class BreadCrumbs extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeDot: 1,
      BreadCrumbsVisible: 0,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    var lastPart = window.location.href.split("/").pop();

    if (!lastPart) {
      const scrollY = window.scrollY;
      const scrollY_bottom = window.scrollY + window.innerHeight;

      if (scrollY >= document.getElementById("goal").offsetTop - 51) {
        this.setState({ activeDot: 1, BreadCrumbsVisible: 1 });
      }
      if (
        scrollY <= document.getElementById("goal").offsetTop - 51 ||
        scrollY_bottom - 80 >= document.getElementById("footer").offsetTop
      ) {
        this.setState({ BreadCrumbsVisible: 0 });
      }
      if (scrollY >= document.getElementById("about").offsetTop - 51) {
        this.setState({ activeDot: 2 });
      }
      if (scrollY >= document.getElementById("quality").offsetTop - 51) {
        this.setState({ activeDot: 3 });
      }
      if (scrollY >= document.getElementById("kontakt").offsetTop - 51) {
        this.setState({ activeDot: 4 });
      }
    }
  };

  render() {
    return (
      <div
        className={
          this.state.BreadCrumbsVisible
            ? "BreadCrumbsWrapper"
            : "BreadCrumbsWrapper hidden"
        }
        onScroll={this.onScroll}
        id="breadCrumbs"
      >
        <div
          className={
            this.state.activeDot == 1 ? "bc1-dot-text active" : "bc1-dot-text"
          }
          onClick={() => this.scrollToElement("goal")}
        >
          <div
            className={
              this.state.activeDot == 1 ? "bc-dot-1 active" : "bc-dot-1"
            }
          />
          <div className="bc-text1 h-xs">{translate("breadcrumbs.goal")}</div>
        </div>
        <div
          className={
            this.state.activeDot == 2 ? "bc2-dot-text active" : "bc2-dot-text"
          }
        >
          <div
            className={
              this.state.activeDot == 2 ? "bc-dot-2 active" : "bc-dot-2"
            }
          />
          <div className="bc-text2 h-xs">
            {translate("breadcrumbs.about-us")}
          </div>
        </div>
        <div
          className={
            this.state.activeDot == 3 ? "bc3-dot-text active" : "bc3-dot-text"
          }
        >
          <div
            className={
              this.state.activeDot == 3 ? "bc-dot-3 active" : "bc-dot-3"
            }
          />
          <div className="bc-text3 h-xs">
            {translate("breadcrumbs.quality")}
          </div>
        </div>
        <div
          className={
            this.state.activeDot == 4 ? "bc4-dot-text active" : "bc4-dot-text"
          }
        >
          <div
            className={
              this.state.activeDot == 4 ? "bc-dot-4 active" : "bc-dot-4"
            }
          />
          <div className="bc-text4 h-xs">
            {translate("breadcrumbs.contact-us")}
          </div>
        </div>
      </div>
    );
  }
}

export default BreadCrumbs;

import React from "react";
import translate from "../../lang/translate";

import "./ReservationWidget.scss";

function MonthRow() {
  return (
    <div className="rw-month-row">
      {new Date().toLocaleDateString("pl-PL", { month: "long" }).toUpperCase()}
    </div>
  );
}

function WeekDaysRow(props) {
  const weekDays_PL = ["pn.", "wt.", "śr.", "czw.", "pt.", "sob.", "niedz."];
  const weekDays_DE = ["Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa.", "So."];
  const weekDays_EN = ["Mo.", "Tu.", "We.", "Thu.", "Fr.", "Sa.", "Su."];
  let weekDays;
  weekDays =
    props.localeState == "pl-PL"
      ? weekDays_PL
      : props.localeState == "de-DE"
      ? weekDays_DE
      : weekDays_EN;

  const isWeekend = (dayIdx) => dayIdx > 4;

  return (
    <div className=" rw-table-row">
      {weekDays.map((day, idx) => (
        <div
          key={day}
          className={`rw-table-cell rw-week-day ${
            isWeekend(idx) && "rw-inactive"
          }`}
        >
          {day}
        </div>
      ))}
    </div>
  );
}

function DayCell({ date, currentSelection, onClick }) {
  const isPast = (date) => date.valueOf() < new Date().valueOf() - 1000;

  const isWeekend = (date) => date.getDay() === 0 || date.getDay() === 6;

  const isInactive = (date) => isPast(date) || isWeekend(date);

  const isSelected = () =>
    currentSelection && currentSelection.getDate() === date.getDate();

  const getClassName = () =>
    `rw-table-cell ${isSelected() && "rw-selected"} ${
      isInactive(date) && "rw-inactive"
    }`;

  const onConfirm = () => {
    onClick(date);
  };

  return (
    <div className={getClassName()} onClick={onConfirm}>
      {date.getDate().toString().padStart(2, "0")}
    </div>
  );
}

function DaysRow({ week, onDayChosen, currentSelection }) {
  const currentDay = (new Date().getDay() + 6) % 7; // So that Sunday is 7th

  const shiftDate = (offset) => {
    const date = new Date();
    date.setDate(date.getDate() + offset);
    return date;
  };

  return (
    <div className="rw-table-row">
      {[...Array(7).keys()].map((n) => (
        <DayCell
          key={n}
          date={shiftDate(n - currentDay + week * 7)}
          onClick={onDayChosen}
          currentSelection={currentSelection}
        />
      ))}
    </div>
  );
}

function HoursHeader() {
  return <div className="rw-hours-header">{translate("contact.hour")}</div>;
}

function HoursRow({
  currentSelection,
  onTimeChosen,
  timeStartVal,
  timeEndVal,
}) {
  const timeStart = timeStartVal;
  const timeEnd = timeEndVal;

  return (
    <div className="rw-hours-row rw-table-row">
      {Array.from(Array(timeEnd - timeStart), (_, i) => timeStart + i).map(
        (n) =>
          n <= 20 ? (
            <div
              key={n}
              className={`rw-table-cell ${
                currentSelection === n ? "rw-selected" : ""
              }`}
              onClick={() => onTimeChosen(n)}
            >{`${n}`}</div>
          ) : (
            <div key={n} className="rw-table-cell none"></div>
          )
      )}
    </div>
  );
}

class ResevationWidget extends React.Component {
  constructor(props) {
    super(props);
    this.widgetRef = React.createRef();
  }
  state = {
    /**
     * @type {Date | null}
     */
    chosenDay: null,
    /**
     * @type {number | null}
     */
    chosenTime: null,
    /**
     * @type {boolean}
     */
    isOpen: false,
    /**
     * @type {string}
     */
    choice: "",
    /**
     * @type {boolean}
     */
    pointHasVal: false,
    pointArr: [],
    newPoint: "",
  };

  componentDidMount() {
    window && document.addEventListener("click", this.onClickOutside);
  }

  componentWillUnmount() {
    window && document.removeEventListener("click", this.onClickOutside);
  }

  componentDidUpdate = () => {
    if (this.props.resetChoice)
      this.setState({ choice: "", chosenDay: null, chosenTime: null });
  };

  onClickOutside = (e) => {
    if (this.state.isOpen && !this.widgetRef.current.contains(e.target)) {
      this.closeWidget();
    }
  };

  canConfirm = () => this.state.chosenDay && this.state.chosenTime;

  onDayChosen = (day) => {
    this.setState({ chosenDay: day });
  };

  onTimeChosen = (time) => {
    this.setState({ chosenTime: time });
  };

  getChoice = () => {
    if (!this.canConfirm()) {
      return "";
    }

    const choice = this.state.chosenDay;
    choice.setHours(this.state.chosenTime, 0, 0, 0);

    return choice.toLocaleDateString(this.props.localeState, {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  onConfirm = (e) => {
    e.preventDefault();
    const choice = this.getChoice();
    this.setState({ choice, isOpen: false });
    this.props.onChange({ target: { name: this.props.name, value: choice } });
  };

  onJobConfirm = (e, item) => {
    e.preventDefault();

    this.setState({ choice: item, isOpen: false });
    this.props.onChange({
      target: { name: this.props.name, value: item },
    });
  };

  openWidget = (e) => {
    e.preventDefault();
    this.setState({ isOpen: !this.state.isOpen });
  };

  closeWidget = () => this.setState({ isOpen: false });

  isWeekend = () => new Date().getDay() === 0 || new Date().getDay() === 6;

  addJobPoint = (e) => {
    e.preventDefault();
    let firstNameInput = document.getElementById(this.props.idName);

    this.setState(
      { choice: this.state.newPoint, isOpen: false, pointHasVal: false },
      () => {
        this.setState(
          (prevState) => ({
            pointArr: [...prevState.pointArr, this.state.newPoint],
          }),
          () => {
            this.props.onChange({
              target: { name: this.props.name, value: this.state.pointArr },
            });
          }
        );
      }
    );

    firstNameInput.value = "";
  };

  deleteJobPoint = (e, item) => {
    e.preventDefault();
    let firstNameInput = document.getElementById(this.props.idName);

    let newArr = this.state.pointArr.filter((element) => {
      if (element != item) return element;
    });

    this.setState({ choice: item, isOpen: false, pointArr: newArr }, () => {
      this.props.onChange({
        target: { name: this.props.name, value: this.state.pointArr },
      });
    });
  };

  changePoint = () => {
    let firstNameInput = document.getElementById(this.props.idName);
    document.getElementById(this.props.idName) &&
    document.getElementById(this.props.idName).value != ""
      ? this.setState({ pointHasVal: true, newPoint: firstNameInput.value })
      : this.setState({ pointHasVal: false, newPoint: "" });
  };

  render() {
    return (
      <div
        className={
          !this.state.isOpen
            ? this.props.name == "admin"
              ? "reservation-widget admin"
              : this.props.add == "add"
              ? "reservation-widget addJob"
              : "reservation-widget"
            : this.props.name == "admin"
            ? "reservation-widget open admin"
            : this.props.add == "add"
            ? "reservation-widget addJob open"
            : "reservation-widget open"
        }
        ref={this.widgetRef}
      >
        {this.props.add ? (
          <React.Fragment>
            {this.props.name == "admin" ? (
              <input
                className="reservation-widget__input admin"
                placeholder="Dodaj punkt"
                name={this.props.name}
                type="text"
                value={this.props.value ? this.props.value : null}
                onInput={(e) => e.preventDefault()}
                defaultValue={this.state.choice}
                onChange={() => this.changePoint()}
                id={this.props.idName}
              />
            ) : (
              <input
                className={
                  this.props.add == "add"
                    ? "reservation-widget__input addJob"
                    : "reservation-widget__input"
                }
                placeholder="Dodaj punkt"
                name={this.props.name}
                type="text"
                onInput={(e) => e.preventDefault()}
                defaultValue={this.state.choice}
                onChange={() => this.changePoint()}
                id={this.props.idName}
              />
            )}

            {!this.state.pointHasVal ? (
              this.state.pointArr.length ? (
                this.state.isOpen ? (
                  <i
                    className="las la-angle-up"
                    onClick={() => this.setState({ isOpen: false })}
                  />
                ) : (
                  <i className="las la-angle-down" onClick={this.openWidget} />
                )
              ) : (
                <i className="las la-minus"></i>
              )
            ) : (
              <i className="las la-plus-circle" onClick={this.addJobPoint} />
            )}
          </React.Fragment>
        ) : this.props.name == "admin" ? (
          <input
            className="reservation-widget__input admin"
            placeholder={this.props.placeholder}
            name={this.props.name}
            type="text"
            readOnly
            onInput={(e) => e.preventDefault()}
            onClick={this.openWidget}
            defaultValue={this.state.choice}
            value={this.props.value ? this.props.value : null}
            id={
              this.props.admin
                ? this.props.idName
                  ? this.props.idName
                  : "admin"
                : this.props.idName
            }
          />
        ) : (
          <input
            className="reservation-widget__input"
            placeholder={this.props.placeholder}
            name={this.props.name}
            type="text"
            readOnly
            onInput={(e) => e.preventDefault()}
            onClick={this.openWidget}
            defaultValue={this.state.choice}
            id={
              this.props.admin
                ? this.props.idName
                  ? this.props.idName
                  : "admin"
                : this.props.idName
            }
          />
        )}
        {this.state.isOpen &&
          (this.props.jobTitles ? (
            this.props.jobTitles.length ? (
              <div className="rw-container job">
                {this.props.jobTitles.map((item) => {
                  return (
                    <p
                      className="job-title p-xl"
                      onClick={(e) => this.onJobConfirm(e, item)}
                    >
                      {item}
                    </p>
                  );
                })}
              </div>
            ) : null
          ) : this.props.add ? (
            this.state.pointArr.length ? (
              <div className="rw-container job">
                {this.state.pointArr.map((item) => {
                  return (
                    <p
                      className="job-title p-xl addJob"
                      onClick={(e) => this.deleteJobPoint(e, item)}
                    >
                      {item}
                    </p>
                  );
                })}
              </div>
            ) : null
          ) : (
            <div className="rw-container">
              <WeekDaysRow localeState={this.props.localeState} />
              <div>
                <DaysRow
                  week={this.isWeekend() ? 1 : 0}
                  currentSelection={this.state.chosenDay}
                  onDayChosen={this.onDayChosen}
                />
                <DaysRow
                  week={this.isWeekend() ? 2 : 1}
                  currentSelection={this.state.chosenDay}
                  onDayChosen={this.onDayChosen}
                />
              </div>
              <HoursHeader />
              <HoursRow
                currentSelection={this.state.chosenTime}
                onTimeChosen={this.onTimeChosen}
                timeStartVal={9}
                timeEndVal={16}
              />
              <HoursRow
                currentSelection={this.state.chosenTime}
                onTimeChosen={this.onTimeChosen}
                timeStartVal={16}
                timeEndVal={23}
              />
              <div className={"rw-confirmation"}>
                <button disabled={!this.canConfirm()} onClick={this.onConfirm}>
                  {translate("contact.select")}
                </button>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

export default ResevationWidget;

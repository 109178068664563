import React, { PureComponent } from "react";

import "./Main.scss";
import Layout from "../../components/Layout";
import translate from "../../lang/translate";

class Main extends PureComponent {
  constructor(props) {
    super(props);
    this.switchText = this.switchText.bind(this);
    this.state = {
      activeInfo: 0,
    };
  }

  switchText() {
    this.setState({ activeInfo: (this.state.activeInfo + 1) % 3 });
  }

  componentDidMount() {
    setInterval(this.switchText, 10000);
  }

  scrollToElement(id) {
    window.scrollTo({
      top: document.getElementById(id).offsetTop - 30,
      left: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <Layout background="engine" direction="column">
        <div className="MainWrapper" id="main">
          <div className="p-lg MainInfo">
            {this.state.activeInfo == 1
              ? translate("main.first-slide")
              : this.state.activeInfo == 2
              ? translate("main.second-slide")
              : translate("main.third-slide")}
          </div>
          <div className="dots">
            <div
              className={this.state.activeInfo == 0 ? "dot-1 active" : "dot-1"}
              onClick={() => this.setState({ activeInfo: 0 })}
            />
            <div
              className={this.state.activeInfo == 1 ? "dot-2 active" : "dot-2"}
              onClick={() => this.setState({ activeInfo: 1 })}
            />
            <div
              className={this.state.activeInfo == 2 ? "dot-3 active" : "dot-3"}
              onClick={() => this.setState({ activeInfo: 2 })}
            />
          </div>
          {/* <i
            className="las la-angle-down"
            onClick={() => this.scrollToElement("goal")}
          /> */}
        </div>
      </Layout>
    );
  }
}

export default Main;

import React from "react";
import "./Layout.scss";

const Layout = (props) => {
  let center = props.center ? " center" : "";
  let direction = props.direction == "column" ? " column" : " row";
  let noMargin = props.noMargin == true ? " no" : "";
  return (
    <div className={props.id + " "}>
      <div
        id={props.id}
        className={`background background--${props.background}`}
      >
        <div className={"container" + direction + center + noMargin}>
          {" "}
          {props.children}{" "}
        </div>
      </div>
    </div>
  );
};

export default Layout;

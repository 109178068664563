import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./ResetPassword.scss";
import Button from "../../components/Button/Button";
import Layout from "../../components/Layout";
import logo from "../../assets/images/logo.svg";
import { NavLink, Navigate } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import translate from "../../lang/translate";
const axios = require("axios");
var md5 = require("md5");

class ResetPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      hasError: false,
      redirect: "",
      messageValues: {
        password: "",
        passwordv2: "",
      },
    };
  }

  componentDidMount = () => {
    this.props.changeUrl("/reset-password");
  };

  handleSubmit = (mailTokenVal, emailVal) => {
    const data = {
      passwordv2: md5(this.state.messageValues.passwordv2),
      password: md5(this.state.messageValues.password),
      email: emailVal,
      mailToken: mailTokenVal,
      resetPassword: 1,
      redirect: "",
    };
    let self = this;
    clearTimeout(this.timer);
    if (data.password == data.passwordv2) {
      if (this.state.messageValues.password.length < 8) {
        NotificationManager.error(translate("n.short-password"));
      } else {
        const form = axios
          .post("/register-user", {
            data,
          })
          .then(function (response) {
            if (response.data.ok) {
              NotificationManager.success(
                translate("n.password-reset-success")
              );

              self.setState({ redirect: "/login" });
            } else {
              NotificationManager.error(translate("n.password-reset-error"));
            }
          })
          .catch(function (error) {
            NotificationManager.error(translate("n.password-reset-error"));
          });
      }
    } else {
      NotificationManager.error(translate("n.passwords-not-equal"));
    }

    const secondNameInput = document.getElementById("password");
    const thirdNameInput = document.getElementById("passwordv2");

    secondNameInput.value = "";
    thirdNameInput.value = "";
  };

  handleKeyDown = (e, lastPart, secondlastPart) => {
    if (e.key === "Enter") {
      this.handleSubmit(lastPart, secondlastPart);
    }
  };

  handleChange = (event, name) => {
    this.setState({
      messageValues: {
        ...this.state.messageValues,
        [name]: event.target.value,
      },
    });
  };

  render() {
    var lastPart = window.location.href.split("/").pop();
    var secondlastPart = window.location.href.split("/").slice(-2)[0];
    if (this.state.redirect != "") {
      return <Navigate replave to={this.state.redirect} />;
    } else
      return (
        <div className="LoginWrapper">
          <Layout background="white">
            <div className="login-background-img">
              <img src={logo} className="login-logo" alt="sqar logo" />
              <div className="login-title p-md">
                {translate("reset-password.header")}
                {secondlastPart}
              </div>
              <div className="login-small-title p-md"></div>
              <div className="login-list-input">
                <p className="p-xs login-list-input__header">
                  {translate("reset-password.new-password")}
                </p>
                <input
                  className="login-list-input__input"
                  placeholder="Nowe hasło"
                  secureTextEntry={true}
                  type="password"
                  onChange={(e) => this.handleChange(e, "password")}
                  onKeyDown={(e) =>
                    this.handleKeyDown(e, lastPart, secondlastPart)
                  }
                  id="password"
                />
              </div>
              <div className="login-list-input">
                <p className="p-xs login-list-input__header">
                  {translate("reset-password.repeat-password")}
                </p>
                <input
                  className="login-list-input__input second"
                  placeholder="Nowe hasło"
                  secureTextEntry={true}
                  type="password"
                  onChange={(e) => this.handleChange(e, "passwordv2")}
                  onKeyDown={(e) =>
                    this.handleKeyDown(e, lastPart, secondlastPart)
                  }
                  id="passwordv2"
                />
              </div>

              <div className="bottom-part">
                <div
                  className="button-wrapper"
                  onClick={() => this.handleSubmit(lastPart, secondlastPart)}
                >
                  <Button
                    buttonStyle="filled"
                    title={translate("reset-password.button")}
                    className="login-button"
                  />
                </div>
              </div>
            </div>
            <div className="login-background-img-right">
              <div className="login-title-right p-xl  ">REPORT DATA BASE</div>
              <div className="login-container-right">
                <div className="login-desc-img-right">
                  <i className="las la-scroll p-lg" />
                  <div className="login-text-container">
                    <div className="login-desc-title-right p-md ">
                      {translate("rdb.header1")}
                    </div>
                    <div className="login-desc-right  p-2xs">
                      {translate("rdb.desc1")}
                    </div>
                  </div>
                </div>

                <div className="login-desc-img-right">
                  <i className="las la-calendar-day p-md"></i>
                  <div className="login-text-container">
                    {" "}
                    <div className="login-desc-title-right p-md ">
                      {translate("rdb.header2")}
                    </div>
                    <div className="login-desc-right p-2xs">
                      {translate("rdb.desc2")}
                    </div>
                  </div>
                </div>

                <div className="login-desc-img-right">
                  <i className="las la-laptop-code p-md"></i>
                  <div className="login-text-container">
                    <div className="login-desc-title-right p-md ">
                      {translate("rdb.header3")}
                    </div>
                    <div className="login-desc-right p-2xs">
                      {translate("rdb.desc3")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Layout>
        </div>
      );
  }
}

export default ResetPassword;

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//import { Test } from './ErrorPage.styles';
import Layout from "../../components/Layout/Layout";
import "./ErrorPage.scss";
import Button from "../../components/Button";
import { NavLink } from "react-router-dom";
import translate from "../../lang/translate";

class ErrorPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidMount = () => {
    this.props.changeUrl("/404");
  };

  render() {
    return (
      <div className="errPage-Wrapper">
        <Layout background="white">
          <div className="error-background-img">
            <i className="las la-tools" />
            <div className="error-title h-lg">{translate("404.error")}</div>
            <div className="error-desc p-lg">{translate("404.page")}</div>
            <NavLink to="/">
              <Button buttonStyle="filled" title={translate("404.back")} />
            </NavLink>
          </div>
        </Layout>
      </div>
    );
  }
}

export default ErrorPage;

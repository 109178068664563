import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./Goal.scss";
import GreyBlock from "../../components/GreyBlock/GreyBlock";
import Layout from "../../components/Layout";
import Separator from "../../components/Separator/Separator";
import translate from "../../lang/translate";
class Goal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render() {
    var icons = ["las la-search", "las la-certificate", "las la-network-wired"];
    var titles = [
      translate("goal.title2_1"),
      translate("goal.title2_2"),
      translate("goal.title2_3"),
    ];
    var desc = [
      translate("goal.desc2_1"),
      translate("goal.desc2_2"),
      translate("goal.desc2_3"),
    ];

    var icons2 = ["las la-truck", "las la-check-circle"];
    var titles2 = [
      translate("goal.title1_1"),
      translate("goal.title1_2"),
      "button",
    ];
    var desc2 = [translate("goal.desc1_1"), translate("goal.desc1_2")];
    return (
      <Layout background="white" direction="column" id="goal">
        <Separator content={translate("separator.goal")} />
        <div className="GoalWrapper">
          <div className="GoalText first h-sm">{translate("goal.header1")}</div>
          <div className="GoalText h-sm">{translate("goal.header2")}</div>
          <GreyBlock icons={icons2} titles={titles2} desc={desc2} />
          <div className="GoalText h-sm">{translate("goal.header3")}</div>
          <GreyBlock last="last" icons={icons} titles={titles} desc={desc} />
        </div>
      </Layout>
    );
  }
}

export default Goal;

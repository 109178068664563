import React, { Component } from "react";

import Layout from "../../components/Layout";
import ListInput from "../../components/ListInput";
import Button from "../../components/Button";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import "./Form.scss";
import Separator from "../../components/Separator/Separator";
import translate from "../../lang/translate";
const axios = require("axios");
const API_KEY =
  "3f8adbcd057bc40a6c1cbe418ac02954277d6f7e89c848a1b255237c687c4ea9";

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    consent: 0,
    resetChoice: 0,
    selectedFile: null,
    base64URL: "",
    messageValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      calendar: "",
      companyname: "",
      message: "",
      job: "",
    },
    jobOfferValues: {
      jobName: "",
      jobPlace: "",
      jobTime: "",
      jobContract: "",
      jobOfferPoints: [],
      jobRequirePoints: [],
      message: "",
    },
  });

  resetState = () => {
    this.setState(this.getInitialState());
  };

  componentDidMount = () => {
    this.resetState();
  };

  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object

        baseURL = reader.result;

        resolve(baseURL);
      };
    });
  };

  onFileChange = (e) => {
    // Update the state

    let { selectedFile } = this.state;
    selectedFile = e.target.files[0];

    this.getBase64(selectedFile)
      .then((result) => {
        selectedFile["base64"] = result;

        this.setState({
          base64URL: result,
          selectedFile,
        });
      })
      .catch((err) => {});

    this.setState({
      selectedFile: e.target.files[0],
    });
  };

  // On file upload (click the upload button)
  // onFileUpload = () => {
  //   // Create an object of formData
  //   const formData = new FormData();

  //   // Update the formData object
  //   formData.append(
  //     "myFile",
  //     this.state.selectedFile,
  //     this.state.selectedFile.name
  //   );

  //   // Details of the uploaded file

  //   // Request made to the backend api
  //   // Send formData object
  //   axios.post("api/uploadfile", formData);
  // };

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  // validatePhone(phone) {
  //   var re = /^\d*$/;
  //   return re.test(phone);
  // }

  handleSubmit = (event) => {
    event.preventDefault();
    let errorValues = [];
    let errorMessage = "";
    let messageCorrect = 1;
    if (this.props.title == "addJob") {
      if (this.state.jobOfferValues.jobContract == "") {
        this.props.localeState == "pl-PL"
          ? errorValues.push("rodzaj umowy")
          : this.props.localeState == "en-US"
          ? errorValues.push("contract type")
          : errorValues.push("Vertragsart");
      }
      if (this.state.jobOfferValues.jobName == "") {
        this.props.localeState == "pl-PL"
          ? errorValues.push("nazwa stanowiska")
          : this.props.localeState == "en-US"
          ? errorValues.push("job name")
          : errorValues.push("Job Name");
      }
      if (this.state.jobOfferValues.jobOfferPoints == []) {
        this.props.localeState == "pl-PL"
          ? errorValues.push("punkty oferty")
          : this.props.localeState == "en-US"
          ? errorValues.push("offer points")
          : errorValues.push("Angebotspunkte");
      }
      if (this.state.jobOfferValues.jobRequirePoints == []) {
        this.props.localeState == "pl-PL"
          ? errorValues.push("punkty wymagań")
          : this.props.localeState == "en-US"
          ? errorValues.push("requirement points")
          : errorValues.push("Bedarfspunkte");
      }
      if (this.state.jobOfferValues.jobPlace == "") {
        this.props.localeState == "pl-PL"
          ? errorValues.push("miejsce pracy")
          : this.props.localeState == "en-US"
          ? errorValues.push("job place")
          : errorValues.push("Arbeitsort");
      }
      if (this.state.jobOfferValues.jobTime == "") {
        this.props.localeState == "pl-PL"
          ? errorValues.push("wymiar czasu pracy")
          : this.props.localeState == "en-US"
          ? errorValues.push("job time")
          : errorValues.push("Job-Zeit");
      }
      if (this.state.jobOfferValues.message == "") {
        this.props.localeState == "pl-PL"
          ? errorValues.push("opis stanowiska")
          : this.props.localeState == "en-US"
          ? errorValues.push("job description")
          : errorValues.push("Stellenbeschreibung");
      }
    } else {
      if (this.state.consent == 0) {
        messageCorrect = 0;
        NotificationManager.error(translate("n.consent"));
      } else {
        if (this.state.messageValues.firstName == "") {
          this.props.localeState == "pl-PL"
            ? errorValues.push("imię")
            : this.props.localeState == "en-US"
            ? errorValues.push("firstname")
            : errorValues.push("Vorname");
        }
        if (this.state.messageValues.lastName == "") {
          this.props.localeState == "pl-PL"
            ? errorValues.push("nazwisko")
            : this.props.localeState == "en-US"
            ? errorValues.push("lastname")
            : errorValues.push("Nachname");
        }
        if (this.state.messageValues.email == "") {
          errorValues.push("e-mail");
        }
        if (this.state.messageValues.phone == "" && this.props.title != "job") {
          this.props.localeState == "pl-PL"
            ? errorValues.push("telefon")
            : this.props.localeState == "en-US"
            ? errorValues.push("phone")
            : errorValues.push("Telefonnumer");
        }
        if (
          this.state.messageValues.calendar == "" &&
          this.props.title != "job"
        ) {
          this.props.localeState == "pl-PL"
            ? errorValues.push("preferowany czas kontaktu")
            : this.props.localeState == "en-US"
            ? errorValues.push("preferred contact-time")
            : errorValues.push("bevorzugte Kontaktzeit");
        }
        if (this.state.messageValues.message == "") {
          this.props.localeState == "pl-PL"
            ? errorValues.push("wiadomość")
            : this.props.localeState == "en-US"
            ? errorValues.push("message")
            : errorValues.push("Nachricht");
        }
        if (
          this.state.messageValues.companyname == "" &&
          this.props.title != "job"
        ) {
          this.props.localeState == "pl-PL"
            ? errorValues.push("nazwa firmy lub organizacji")
            : this.props.localeState == "en-US"
            ? errorValues.push("company or organisation name")
            : errorValues.push("Name des Unternehmens oder der Organisation");
        }
        if (this.state.messageValues.job == "" && this.props.title == "job") {
          this.props.localeState == "pl-PL"
            ? errorValues.push("stanowisko pracy")
            : this.props.localeState == "en-US"
            ? errorValues.push("job position")
            : errorValues.push("Stellenwert");
        }
        if (this.state.selectedFile == null && this.props.title == "job") {
          this.props.localeState == "pl-PL"
            ? errorValues.push("załącznik cv")
            : this.props.localeState == "en-US"
            ? errorValues.push("cv attachment")
            : errorValues.push("CV Anhang");
        }
      }
    }
    if (errorValues.length > 1) {
      messageCorrect = 0;
      errorValues.map((value, index) => {
        errorMessage += value;
        if (index < errorValues.length - 1) {
          errorMessage += ", ";
        }
      });
      this.props.localeState == "pl-PL"
        ? NotificationManager.error("Pola " + errorValues + " są puste.")
        : this.props.localeState == "en-US"
        ? NotificationManager.error("Fields " + errorValues + " are empty")
        : NotificationManager.error("Felder " + errorValues + " sind leer");
    }

    if (errorValues.length == 1) {
      messageCorrect = 0;
      errorMessage = errorValues[0];

      this.props.localeState == "pl-PL"
        ? NotificationManager.error("Pole " + errorMessage + " jest puste.")
        : this.props.localeState == "en-US"
        ? NotificationManager.error("Field " + errorMessage + " is empty")
        : NotificationManager.error("Feld " + errorMessage + " ist leer");
    }
    if (this.props.title != "addJob") {
      if (!this.validateEmail(this.state.messageValues.email)) {
        messageCorrect = 0;
        NotificationManager.error(translate("n.wrong-email"));
      }
    }

    // if (!this.validatePhone(this.state.messageValues.email)) {
    //   messageCorrect = 0;
    //   NotificationManager.error("Nieprawidłowy numer telefonu");
    // }

    if (messageCorrect == 1) {
      // const formData = new FormData();
      // formData = this.state.messageValues;
      let data;
      if (this.props.title != "addJob") {
        data = {
          firstName: this.state.messageValues.firstName,
          lastName: this.state.messageValues.lastName,
          email: this.state.messageValues.email,
          phone: this.state.messageValues.phone,
          // calendar: this.state.messageValues.calendar,
          // companyname: this.state.messageValues.companyname,
          company: this.state.messageValues.companyname,
          date: this.state.messageValues.calendar,
          message: this.state.messageValues.message,
          job: this.state.messageValues.job,
          attachment: this.state.base64URL ? this.state.base64URL : "",
          attachmentName: this.state.selectedFile
            ? this.state.selectedFile.name
            : "",
        };
      } else {
        data = {
          jobName: this.state.jobOfferValues.jobName,
          jobPlace: this.state.jobOfferValues.jobPlace,
          jobTime: this.state.jobOfferValues.jobTime,
          jobContract: this.state.jobOfferValues.jobContract,
          jobOfferPoints: this.state.jobOfferValues.jobOfferPoints,
          jobRequirePoints: this.state.jobOfferValues.jobRequirePoints,
          message: this.state.jobOfferValues.message,
        };
      }
      let self = this;
      if (this.props.title != "addJob") {
        const form = axios
          .post("/api/form", {
            data,
          })
          .then(function (response) {
            if (response.data.ok) {
              NotificationManager.success(translate("n.formula-sent-success"));
            } else {
              NotificationManager.error(translate("n.formula-send-error"));
            }
          })
          .catch(function (error) {
            NotificationManager.error(translate("n.formula-send-error"));
          });
      } else {
        let self = this;
        const form = axios
          .post("/upLoadJob", {
            data,
          })
          .then(function (response) {
            if (response.data.ok) {
              NotificationManager.success(translate("n.offer-sent-success"));
              if (self.props.adminGetJobs) {
                self.props.adminGetJobs();
              }
            } else {
              NotificationManager.error(translate("n.offer-send-error"));
            }
          })
          .catch(function (error) {
            NotificationManager.error(translate("n.offer-send-error"));
          });
      }

      errorValues = [];
      errorMessage = "";
      messageCorrect = 1;
      this.resetState();
      event.target.reset();
      // [...event.target.querySelectorAll("input, textarea")].forEach((input) => {
      //   input.value = "";
      // });
    }
    // document.getElementsByClassName("reservation-widget__input").value = "";
    // document.forms["contact-form"].reset();
    this.setState({ consent: 0, resetChoice: 1 }, () => {
      this.setState({ resetChoice: 0 });
      event.target.reset();
    });
  };

  handleChange = (event, name) => {
    this.setState({
      messageValues: {
        ...this.state.messageValues,
        [name]: event.target.value,
      },
    });
  };

  handleJobChange = (event, name) => {
    this.setState({
      jobOfferValues: {
        ...this.state.jobOfferValues,
        [name]: event.target.value,
      },
    });
  };

  render() {
    return (
      <Layout
        id={
          this.props.showContent || this.props.showContent === undefined
            ? "kontakt"
            : "kontakt-empty"
        }
        direction="column"
        center="center"
        background="white"
        noMargin={this.props.noMargin ? true : false}
      >
        {this.props.showContent || this.props.showContent === undefined ? (
          <div className="kontakt__main-container">
            {this.props.title != "addJob" ? (
              <Separator
                content={
                  this.props.title && this.props.title == "job"
                    ? translate("separator.job-apply")
                    : translate("separator.contact-us")
                }
              />
            ) : null}

            <form
              className={
                this.props.title == "job"
                  ? "col-6 list-input-wrapper job"
                  : this.props.title == "addJob"
                  ? "col-6 list-input-wrapper addJob"
                  : "col-6 list-input-wrapper"
              }
              onSubmit={this.handleSubmit}
              id="contact-form"
            >
              {(this.props.title && this.props.title == "job") ||
              (this.props.title && this.props.title == "addJob") ? null : (
                <h2 className="h-md kontakt__header">
                  {translate("contact.welcome")}
                </h2>
              )}

              {this.props.linkData
                ? this.props.linkData.map((data, index) => {
                    return (
                      <ListInput
                        header={data.header}
                        placeholder={data.placeholder}
                        dropdown={data.dropdown}
                        textarea={data.textarea}
                        idName={data.idName}
                        value={this.state.value}
                        checkbox={data.checkbox}
                        description={data.description}
                        localeState={this.props.localeState}
                        handleChange={
                          this.props.title == "addJob"
                            ? this.handleJobChange
                            : this.handleChange
                        }
                        index={index}
                        resetChoice={this.state.resetChoice}
                        type={
                          data.type && data.type == "number" ? data.type : null
                        }
                        select={
                          data.select && data.select == "select"
                            ? data.select
                            : null
                        }
                        jobTitles={
                          this.props.jobTitles ? this.props.jobTitles : null
                        }
                        jobTime={this.props.jobTime ? this.props.jobTime : null}
                        add={data.add ? "add" : ""}
                        points={data.points ? data.points : null}
                        time={data.time ? true : false}
                      />
                    );
                  })
                : null}

              {this.props.attachment && this.props.attachment == true ? (
                <div className="kontakt__attachment-container">
                  <p className="kontakt__attachment-desc p-xs">
                    {translate("job.attachment")}
                  </p>
                  <input
                    type="file"
                    accept="application/pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    className="kontakt__attachment-button"
                    onChange={this.onFileChange}
                  />
                </div>
              ) : null}

              {this.props.title != "addJob" ? (
                <div className="kontakt__checkbox-container">
                  <div
                    className={
                      this.state.consent
                        ? "kontakt__custom-checkbox active"
                        : "kontakt__custom-checkbox"
                    }
                    onClick={() => {
                      this.setState({ consent: !this.state.consent });
                    }}
                  />
                  <span className="p-2xs kontakt__checkbox-description">
                    {translate("contact.check-box-desc")}
                  </span>
                </div>
              ) : null}

              {this.props.title == "addJob" ? (
                <div className="kontakt__button-container">
                  <Button
                    buttonStyle="filled"
                    title={translate("admin.add-job-offer-header")}
                  />
                </div>
              ) : (
                <div className="kontakt__button-container">
                  <Button
                    buttonStyle="filled"
                    title={translate("contact.button")}
                  />
                </div>
              )}
            </form>
          </div>
        ) : null}
      </Layout>
    );
  }
}

export default Form;

import { LOCALES } from "../locales";

export default {
  [LOCALES.POLISH]: {
    /*HEADER*/
    "header.about-us": "O NAS",
    "header.ows": "OGÓLNE WARUNKI SPRZEDAŻY",
    "header.contact-us": "KONTAKT",
    "header.quality": "KONTROLA JAKOŚCI",
    "header.job": "Praca",
    "header.home": "STRONA GŁÓWNA",
    "header.logout": "WYLOGUJ",
    "header.user-panel": "LISTA FOLDERÓW",
    "header.admin-panel": "PANEL ADMINISTRATORA",

    /*FOOTER*/
    "footer.company": "FIRMA",
    "footer.goal": "Nasz cel",
    "footer.about-us": "O nas",
    "footer.quality": "KONTROLA JAKOŚCI",
    "footer.quality-delivery": "Kontrola jakości dostawców",
    "footer.quality-end": "Kontrola końcowa",
    "footer.job": "PRACA",
    "footer.job-offers": "Oferty pracy",
    "footer.for-clients": "DLA KLIENTÓW",
    "footer.map":
      "Mapa świata została pobrana ze strony (link poniżej), modyfikacje: zmiana kolorów oraz oznaczenie państw",

    /*BREADCRUMBS*/
    "breadcrumbs.goal": "NASZ CEL",
    "breadcrumbs.about-us": "O NAS",
    "breadcrumbs.quality": "KONTROLA JAKOŚCI",
    "breadcrumbs.contact-us": "KONTAKT",

    /*SEPARATOR*/
    "separator.goal": "NASZ CEL",
    "separator.about-us": "O NAS",
    "separator.quality": "KONTROLA JAKOŚCI",
    "separator.contact-us": "KONTAKT",
    "separator.job": "OFERTY PRACY",
    "separator.job-apply": "Złóż podanie o pracę",

    /*MAIN*/
    "main.first-slide": `SQA&R to przedsięwzięcie zapoczątkowane w 2000 roku, w oparciu o pasję oraz wieloletnie doświadczenia właściciela Przemka Kowalewskiego. Po wielu latach pracy w przemyśle motoryzacyjnym, rozpoczęliśmy współpracę z potentatami na własny rachunek. Od tego czasu nawiązaliśmy współpracę z wieloma firmami. Reprezentując je w wielu krajach. Polska, Rosja, Czechy, Słowacja, Niemcy…`,
    "main.second-slide": `SQA&R to inwestycja w wieloletnie doświadczenie we współpracy z OEM’owymi producentami z branży motoryzacyjnej. Ogromna wiedza w zakresie budowy silników. Znajomość obszarów potencjalnych niezgodności. Innowacyjność oraz zaangażowanie w rozwiązywanie problemów. Umiejętność zaawansowanej analizy, oraz detekcja źródłowych przyczyn powstawania niezgodności.`,
    "main.third-slide": `SQA&R  to średniej wielkości przedsiębiorstwo, działające szybko i dynamicznie, nieskrępowane  dużą strukturą organizacyjną, z najkrótszą drogą od pomysłu do decyzji.`,

    /*GOAL*/
    "goal.header1":
      "Dążenie do satysfakcji klienta poprzez precyzyjne określenie jego oczekiwań.",
    "goal.header2":
      "Doskonalenie procesów własnych w celu rozwoju kompetencji kadry pracowniczej która będzie w stanie sprostać oczekiwaniom klienta.",
    "goal.header3": "Nasze produkty oferujemy w 3 głównych obszarach:",

    "goal.title1_1": "KONTROLA JAKOŚCI DOSTAWCÓW",
    "goal.title1_2": "KONTROLA KOŃCOWA",
    "goal.title2_1": "USŁUGI 100% INSPEKCJI",
    "goal.title2_2": "USŁUGI JAKOŚCIOWE",
    "goal.title2_3": "OUTSOURCING",

    "goal.desc1_1":
      "Dajemy wsparcie, naszym klientom w rozwijaniu jakości dostawców na terenie ich firmy.",
    "goal.desc1_2":
      "Specjalizujemy się w usługach kontroli jakości komponentów i wyrobów gotowych.",
    "goal.desc2_1": "100% kontroli i/lub napraw komponentów",
    "goal.desc2_2":
      "Czasowe oddelegowanie pracownika (do klienta lub jego dostawcy)",
    "goal.desc2_3": "Specjaliści, inżynierowie, rezydenci",
    "goal.button": "Więcej o SQA&R",

    /*ABOUT US*/
    "about.header1": "Proponujemy tę usługę, gdy:",
    "about.point1.1": "dostawca dostarczył wyroby niezgodne",
    "about.point1.2": "klient wymaga 100% kontroli wyprodukowanych części",
    "about.point1.3": "proces jest nie stabilny i wymaga dodatkowych kontroli",
    "about.point1.4":
      "uruchamiasz nowy produkt i tymczasowo potrzebujesz więcej pracowników",

    "about.header2": "Możemy przeprowadzać tę usługę:",
    "about.point2.1": "u dostawcy",
    "about.point2.2": "u klienta",
    "about.point2.3": "w swojej siedzibie",
    "about.point2.4":
      "w specyficznym miejscu (np.: laboratorium, zewnętrzny magazyn)",

    "about.header3": "Możecie na nas polegać, ponieważ:",
    "about.point3.1": "nasz czas realizacji jest minimalny",
    "about.point3.2": "świadczy usługi 24/7",
    "about.point3.3":
      "nasi inspektorzy są doświadczeni, zorientowani w zagadnieniach jakości",
    "about.point3.4": "nasi pracownicy pracują w oparciu o umowę o pracę",
    "about.point3.5": "wspomagamy proces inspekcji wiedzą inżynierską",
    "about.point3.6": "pracujemy zgodnie z normą ISO 9001: 2000",

    "about.header4": "Korzyści wynikające z współpracy z SQA&R:",
    "about.point4.1":
      "zmotywowani pracownicy(umowa o pracę, premie okolicznościowe i grupowe)",
    "about.point4.2":
      "ograniczenie kosztów związanych z zasobami ludzkimi  (rekrutacja, obsługa, szkolenia, odzież etc.)",
    "about.point4.3":
      "współpraca z wybranymi pracownikami, bez zwiększania swego „headcount’u”",
    "about.point4.4": "odpowiedzialność OC oraz NW po stronie usługodawcy",
    "about.point4.5": "doświadczona kadra",

    /*QUALITY-CONTROL*/
    "quality.header1": "KONTROLA JAKOŚCI DOSTAWCÓW",
    "quality.desc1":
      "Zdajemy sobie sprawę, jak ważne są punktualne dostawy części i dodatkowe kontrole, aby nie zaburzać przebiegu produkcji. Dzięki naszym usługom, nasze klientów może być pewnych, że narzędzia potrzebne do prawidłowego funkcjonowania produkcji są w rękach profesjonalistów. To zapewnia stabilność, bezpieczeństwo i punktualność klientów, a także pozwala im oszczędzić czas, poprawiając operacje biznesowe i wskaźniki.",
    "quality.header2": "KONTROLA KOŃCOWA",
    "quality.desc2":
      "Kontrola końcowa umożliwia szybką reakcję na problemy w produkcji bez konieczności zatrzymywania linii produkcyjnej i zaangażowania pracowników. Pozwala również na monitorowanie procesu produkcyjnego i zmniejszenie kosztów związanych z potencjalnymi reklamacjami od ostatecznego klienta. W każdym z naszych projektów zapewniamy wsparcie techniczne i dostarczamy szczegółowy raport z wykonanych usług.",

    /*MAP*/
    "map.header":
      "Państwa, w których aktualnie reprezentujemy naszych klientów",

    /*CONTACT*/
    "contact.check-box-desc":
      "Wyrażam zgodę na gromadzenie, przetwarzanie i wykorzystanie moich danych osobowych przez firmę SQAR engineering solutions",
    "contact.button": "Wyślij wiadomość",
    "contact.welcome": "Zapraszamy do kontaktu odnośnie współpracy",
    "contact.hour": "godz.",
    "contact.select": "Wybierz",

    /*JOB*/
    "job.attachment": "Załącznik CV(przyjmowane formaty doc, docx, pdf): ",
    "job.desc1":
      "SQA&R to dynamicznie rozwijający się oddział międzynarodowego konsorcjum kapitałowego. W naszej firmie rekrutujemy osoby, które z pasją i zaangażowaniem oddają się codziennej pracy. To dzięki ich pracy i wysiłkom przynosimy tak wiele sukcesów naszej firmie.",
    "job.header1": "Oferty pracy – szukamy ludzi z wizją",
    "job.desc2": "Szukamy ludzi, którzy:",
    "job.point1": "zamierzają rozwijać swoje technologiczne kompetencji",
    "job.point2": "czerpią satysfakcję z pracy nad różnorodnymi projektami",
    "job.point3": "lubią myśleć i tworzyć nowe rozwiązania",
    "job.point4":
      "chcą pracować w atmosferze sprzyjającej profesjonalizacji pracy",
    "job.desc3":
      "Nasze środowisko pracy umożliwia rozwijanie się w indywidualnym tempie, co przyczynia się do sukcesów zawodowych i rozwoju kariery. Jeśli chcesz być częścią takiej drużyny, zgłoś swoje zainteresowanie współpracą.",
    "job.header2": "Poszukujemy ludzi na stanowiska:",

    /*LOGIN*/
    "login.header": "Wymagana autoryzacja",
    "login.password": "Hasło:",
    "login.password-placeholder": "Hasło",
    "login.login": "Zaloguj",
    "login.forgot-password": "Zapomniałeś hasła?",
    "login.dont-have-account": "Nie masz konta? Napisz prośbę mailem na adres:",

    /*RDB*/
    "rdb.header1": "Dostęp do raportów online",
    "rdb.desc1":
      "Dzięki aplikcji RDB masz dostęp do aktualnych raportów naszej pracy. Od teraz będziesz miał wszystkie raporty pod ręką, nie ważne gdzie jesteś. Raporty możesz pobierać w formacie XLSX.",
    "rdb.header2": "Dane dostępne 24/7",
    "rdb.desc2":
      "Masz całkowity dostęp do swoich raportów, o każdej porze. Twoje raporty będą codziennie aktualizowane.",
    "rdb.header3": "Aplikacja łatwa w obsłudze",
    "rdb.desc3":
      "Twoje raporty będą przechowywane w aplikacji, która jest bardzo intuicyjna i prosta w obsłudze, jej głównym celem jest ułatwienie ci pracy.",

    /*SEND-EMAIL*/
    "send-email.header": "Podaj adres e-mail aby zresetować hasło: ",
    "send-email.send": "Wyślij",

    /*RESET-PASSWORD*/
    "reset-password.header": "Resetuj hasło dla adresu: ",
    "reset-password.new-password": "Nowe hasło:",
    "reset-password.repeat-password": "Powtórz hasło:",
    "reset-password.button": "Zresetuj hasło",

    /*REGISTER-USER*/
    "register.header": "Załóż konto dla: ",
    "register.password": "Hasło:",
    "register.button": "Zarejestruj",

    /*USER*/
    "user.header": "DOSTĘPNE RAPORTY",
    "user.click-download": "kliknij na raport aby go pobrać",
    "user.click-delete": "kliknij na raport aby go usunąć",
    "user.click-download-button": "Pobierz",
    "user.click-delete-button": "Usuń",
    "user.rep-num": "Numer raportu",
    "user.rep-name": "Nazwa pliku",
    "user.rep-admin": "Administrator",
    "user.rep-date": "Data",
    "user.logout": "Wyloguj",
    "user.folder-choose": "Wybierz folder z listy",
    "user.no-reports": "Brak dostępnych raportów...",

    /*ADMIN*/
    "admin.header": "Panel administratora",
    "admin.user-send-header":
      "Wybierz jednego z użytkowników, któremu chcesz przesłać raport",
    "admin.user-send-user": "Użytkownik:",
    "admin.user-send-folder": "Folder:",
    "admin.user-send-folder-list": "Lista folderów:",
    "admin.user-send-sub-folder": "Podfolder:",
    "admin.user-send-attachment": "Załącznik Raportu(format xslx): ",
    "admin.user-send-button": "Wyślij raport",

    "admin.invite-header": "Wyślij zaproszenie do rejestracji",
    "admin.invite-checkbox": "Nadaj użytkownikowi uprawnienia administrtora",
    "admin.invite-checkbox-super":
      "Nadaj użytkownikowi uprawnienia superadministrtora",
    "admin.invite-button": "Wyślij wiadomość",

    "admin.delete-user-header": "Usuń użytkownika",
    "admin.delete-user-user": "Użytkownik:",
    "admin.delete-user-delete": "Usuń użytkownika",
    "admin.delete-user-delete-confirm": "Potwierdź usunięcie użytkownika",
    "admin.delete-folder-button-label-confirm": "Potwierdź usunięcie folderu",

    "admin.add-job-offer-header": "Dodaj ofertę pracy",

    "admin.delete-job-offer-header": "Usuń stanowisko pracy",
    "admin.delete-job-offer-offer": "Stanowisko pracy:",
    "admin.delete-job-offer-delete": "Usuń stanowisko",
    "admin.delete-job-offer-delete-confirm": "Potwierdź usunięcie stanowiska",

    "admin.logout": "Wyloguj",
    "admin.add-folder-header": "Dodaj nowy folder",
    "admin.folder-delete-header": "Usuń folder",
    "admin.add-folder-button-label": "Dodaj folder",
    "admin.delete-folder-button-label": "Usuń folder",
    "admin.change-folder-button-label": "Zmień folder użytkownika",
    "admin.change-folder-button-confirm": "Potwierdź zmianę folderu",
    "admin.change-folder-success": "Udało się zmienić folder użytkownika",
    "admin.change-folder-error":
      "Wystąpił błąd podczas próby zmiany folderu użytkownika",
    "admin.add-to-mainFolder": "Stwórz nowy folder klienta",
    "admin.path-label": "Ścieżka otwartych folderów ...",
    "admin.download-check": "Pobierz",
    "admin.delete-check": "Usuń",
    "admin.folderCategory": "Foldery",
    "admin.userCategory": "Użytkownicy",
    "admin.jobCategory": "Oferty pracy",

    /*ERROR_404*/
    "404.error": "BŁĄD 404",
    "404.page": "Strona nie dostępna",
    "404.back": "Powrót do strony głównej",

    /*NOTIFICATION*/
    "n.logged-out": "Wylogowano.",
    "n.logout-error": "Problem podczas wylogowania.",
    "n.logged-in": "Zalogowano pomyślnie!",
    "n.download-data-error": "Błąd podczas pobierania danych.",
    "n.file-added": "Dodano plik!",
    "n.add-file-error": "Nie udało się dodać pliku.",
    "n.user-deleted": "Użytkownik został usunięty z bazy.",
    "n.user-delete-error": "Nie udało się usunąć użytkownika.",
    "n.offer-deleted": "Stanowisko zostało usunięte z bazy.",
    "n.offer-delete-error": "Nie udało się usunąć stanowiska.",
    "n.invitation-sent": "Wysłano zaproszenie!",
    "n.invitation-send-error": "Nie udało się wysłać zaproszenia!",
    "n.user-is-in-base": "Użytkownik istnieje w bazie",
    "n.sure-to-delete-user":
      "Czy jesteś pewny że chcesz usunąć wybranego użytkownika?",
    "n.sure-to-delete-offer":
      "Czy jesteś pewny że chcesz usunąć wybranego użytkownika?",
    "n.false-login-data": "Złe dane logowania.",
    "n.login-error": "Problem podczas logowania.",
    "n.short-password": "Hasło musi zawierać conajmniej 8 znaków.",
    "n.passwords-not-equal": "Hasła nie są równe.",
    "n.registration-success": "Rejestracja przebiegła pomyślnie!",
    "n.registration-error": "Rejestracja się nie udała.",
    "n.password-reset-success": "Hasło zresetowano pomyślnie!",
    "n.password-reset-error": "Błąd podczas resetowania hasła.",
    "n.reset-email-sent": "Wysłano mail do zresetowania hasła",
    "n.reset-email-send-error": "Błąd podczas wysyłania maila.",
    "n.download-file-error": "Błąd podczas pobierania pliku.",
    "n.no-reports": "Brak raportów.",

    "n.contract-type": "rodzaj umowy",
    "n.job-name": "nazwa stanowiska",
    "n.job-offer-points": "punkty oferty",
    "n.job-require-points": "punkty wymagań",
    "n.job-place": "miejsce pracy",
    "n.job-time": "wymiar czasu pracy",
    "n.job-description": "opis stanowiska",

    "n.consent": "Musisz zaakceptować zgodę",

    "n.name": "imię",
    "n.lastname": "nazwisko",
    "n.phone": "telefon",
    "n.contact-time": "preferowany czas kontaktu",
    "n.message": "wiadomość",
    "n.company-name": "nazwa firmy lub organizacji",
    "n.job-position": "stanowisko pracy",
    "n.cv": "załącznik cv",

    "n.fields": "Pola",
    "n.field": "Pole",
    "n.are-empty": " są puste.",
    "n.ist-empty": " jest puste.",

    "n.wrong-email": "Nieprawidłowy adres email.",

    "n.formula-sent-success": "Formularz został wysłany.",
    "n.formula-send-error": "Wystąpił błąd podczas wysyłania formularza.",

    "n.formula-sent-success": "Formularz został wysłany.",
    "n.formula-send-error": "Wystąpił błąd podczas wysyłania formularza.",
    "n.offer-sent-success": "Stanowisko zostało dodane pomyślnie.",
    "n.offer-send-error": "Wystąpił błąd podczas dodawania stanowiska.",

    "n.folder-add-success": "Dodano folder pomyślnie",
    "n.folder-add-error": "Wystąpił problem podczas dodawania folderu",
    "n.folder-delete-success": "Usunięto folder pomyślnie",
    "n.folder-delete-confirm": "Potwierdź usunięcie folderu",
    "n.folder-delete-error": "Wystąpił problem podczas usuwania folderu",
    "n.folder-exists": "Folder istnieje w bazie danych",
    "n.file-delete-confirm": "Potwierdź usunięcie pliku",
    "n.file-delete-confirm-button": "Potwierdź usunięcie",
    "n.file-delete-success": "Usunięto folder pomyślnie",
    "n.file-delete-error": "Wystąpił problem w usuwaniu pliku",
  },
};
